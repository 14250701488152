var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import Tooltip from "components/tooltip";
import TextBox from "components/text-box";
import Selector from "components/selector";
import { useAppSelector } from "@redux/hooks";
import { FaQuestionCircle } from "react-icons/fa";
import { asArray } from "shared/helpers/asArray";
import { getImageSource } from "shared/helpers/image";
import { questionCodeMetadataRows } from "../QuestionCode.config";
import { FullWidth, Option, TooltipText } from "constants/styled-components";
import { StyledQuestionCode as Styled } from "components/question-code/QuestionCode.styled";
import { selectPendingQuestionCodeFilters, selectQuestionCodeMetadata } from "@redux/slices/adminPortalSlice";
import { partnerCountryMapping } from "constants/variables";
import { getTypedEntries } from "shared/types/utilityTypes";
var OPTIONS = {
    'photo_sources': [],
    'access_types': []
};
export var MetadataSelectors = function (_a) {
    var localQuestionCode = _a.localQuestionCode, onSelect = _a.onSelect, isFilter = _a.isFilter;
    var pendingFilters = useAppSelector(function (state) { return !!isFilter && selectPendingQuestionCodeFilters(state); });
    var metadata = useAppSelector(selectQuestionCodeMetadata);
    var selectorOptions = useMemo(function () {
        if (!metadata)
            return OPTIONS;
        return questionCodeMetadataRows.reduce(function (options, _a) {
            var source = _a.source;
            options[source] = getTypedEntries(metadata[source]).sort(function (_a, _b) {
                var a = _a[1];
                var b = _b[1];
                return Number(b.name.startsWith('Field Agent')) - Number(a.name.startsWith('Field Agent'));
            })
                .map(function (_a) {
                var id = _a[0], item = _a[1];
                return ({
                    id: parseInt(id),
                    value: item.name,
                    noImg: true,
                    label: (_jsxs(Option, { children: [!('summary' in item) && (_jsx("img", { src: getImageSource(item.country_id
                                    ? partnerCountryMapping[item.country_id]
                                    : __assign(__assign({}, item), { source: "partners" })), alt: item.name })), item.name] }))
                });
            });
            return options;
        }, OPTIONS);
    }, [metadata]);
    var handleSelect = function (field, selectedOptions) {
        if (isFilter) {
            onSelect(field, selectedOptions.map(function (option) { return option.id; }));
        }
        else {
            onSelect(field, selectedOptions[0].id);
        }
    };
    return metadata && (_jsx(TextBox, { title: "Source Data", minWidth: !isFilter && "100%", text: questionCodeMetadataRows.map(function (_a) {
            var title = _a.title, source = _a.source, field = _a.field;
            return (_jsxs(Styled.MetadataSelectors, { children: [_jsxs(FullWidth, { display: "flex", children: [_jsx(Styled.MetadataType, { children: title }), source === "access_types" && (_jsx(Tooltip, { width: "auto", position: "top", unmountHTMLWhenHide: true, html: _jsxs(TooltipText, { maxWidth: "600px", children: [_jsx(Styled.TooltipDescription, { children: "Describes who has access to data from this question code" }), _jsx(Styled.SummaryList, { children: Object.values(metadata[source]).map(function (_a) {
                                                var name = _a.name, summary = _a.summary;
                                                return (_jsxs("li", { children: [_jsx("strong", { children: name }), ": ", summary] }, name));
                                            }) })] }), children: _jsx(FaQuestionCircle, { className: "override-fill" }) }))] }), _jsx(Selector, { portal: true, minWidth: "100%", hideSelectorType: true, isSingle: !isFilter, options: selectorOptions[source], placeholder: "Select ".concat(title.toLowerCase(), "..."), clickAction: function (e) { return handleSelect(field, asArray(e.data)); }, value: pendingFilters && selectorOptions[source].filter(function (option) { var _a; return (_a = pendingFilters[field]) === null || _a === void 0 ? void 0 : _a.includes(option.id); }), defaultValue: !isFilter && selectorOptions[source].find(function (option) {
                            return option.id === localQuestionCode[field];
                        }) })] }, source));
        }) }));
};
