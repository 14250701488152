import { connect } from "react-redux";
import { FaPen, FaQuestionCircle } from "react-icons/fa";
import { PureComponent } from "react";
import { fullWidth, styleFlex } from "constants/variables/index";

import AltCTA from "components/buttons/alternate/index";
import MainCTA from "components/buttons/main/index";
import Tooltip from "components/tooltip/index";
import { TooltipText } from "constants/styled-components/index";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const Wrapper = styled.div`
  transition: ${(props) => props.theme.fastTransition};
  animation: fadeIn 1s;
  align-self: stretch;
  border-radius: 1em;
  margin: 1em;
  border: 1px solid ${(props) => props.theme.grey5};
  background: ${(props) => props.theme.white};
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
  flex: auto;
  box-shadow: ${(props) => props.theme.subtleBoxShadow};
  width: 100%;
  ${media.mobile`
    margin: 0.5em;
  `}
`;
const Header = styled.div`
  width: 100%;
  padding: 0.5em;
  background: ${(props) => props.theme.grey5};
  display: flex;
  border-radius: 8px 8px 0 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.grey5};
`;
const H3 = styled.h3`
  margin: 0em;
  font-size: 1em;
  padding: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.grey1};
`;
const Container = styled.div`
  text-align: ${(props) => (props.leftAligned ? "left" : "center")};
  transition: ${(props) => props.theme.defaultTransition};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  color: ${(props) => props.theme.grey2};
  align-items: center;
  p {
    margin: 0.5em;
    color: ${(props) => props.theme.grey2};
    transition: ${(props) => props.theme.defaultTransition};
    fill: ${(props) => props.theme.grey2};
    hyphens: auto;
    line-height: 1.45em;
  }
  &:hover p {
    fill: ${(props) => props.theme.grey1};
  }
  ${media.mobile`
    padding: 0.5em;
  `}
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: ${(props) => props.theme.grey3};
  }
`;

const Form = styled.form`
  margin: 1em;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  button {
    margin: 1em;
  }
`;

class TextBoxEditable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  cancelEditing() {
    this.toggleEditState();
    if (this.props.handleCancel) {
      this.props.handleCancel();
    }
    if (this.props.additionalCancelHandler) {
      this.props.additionalCancelHandler();
    }
  }

  toggleEditState() {
    const currentState = this.state.isEditing;
    this.setState({
      isEditing: !currentState,
    });
  }

  saveEdits() {
    this.toggleEditState();
    if (this.props.handleSubmit) {
      this.props.handleSubmit();
    }
  }

  render() {
    return (
      <Wrapper>
        <Header style={fullWidth}>
          {this.props.tooltip ? (
            <Tooltip
              unmountHTMLWhenHide
              html={<TooltipText>{this.props.tooltip}</TooltipText>}
              position="top"
            >
              <Title>
                <H3>{this.props.title}</H3>
                <FaQuestionCircle />
              </Title>
            </Tooltip>
          ) : (
            <H3>{this.props.title}</H3>
          )}
          {this.state.isEditing ? (
            <div style={styleFlex}>
              <AltCTA
                text={this.props.translations["40"] || "Cancel"}
                clickAction={this.cancelEditing.bind(this)}
              />
              {this.props.extraCTA && (
                this.props.extraCTA
              )}
              {this.props.hideSave ? null : (
                <MainCTA
                  text="Save"
                  type="button"
                  blue
                  disabled={this.props.disabledSave}
                  clickAction={this.saveEdits.bind(this, this.props.formFields)}
                />
              )}
            </div>
          ) : (
            <AltCTA
              clickAction={this.toggleEditState.bind(this)}
              text={this.props.translations["45"] || "Edit"}
              icon={<FaPen size="0.8em" />}
            />
          )}
        </Header>
        <Container leftAligned={this.props.leftAligned}>
          {this.state.isEditing ? (
            <Form id={this.props.title}>{this.props.formFields}</Form>
          ) : this.props.text === null ? (
            "Nothing yet"
          ) : (
            this.props.text
          )}
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.translations,
});

export default connect(mapStateToProps, null)(TextBoxEditable);
