import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Arcade from "components/arcade";
import Loadable from "@loadable/component";
import { LoadingComponent } from "constants/functions";
import { loadable } from "react-lazily/loadable";
var FeedFilters = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeedFilters" */ "components/modal/feed-filters/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var FeedSort = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeedSort" */ "components/modal/feed-sort/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var FeedLayoutSettings = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeedLayoutSettings" */ "components/modal/feed-layout/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var Collection = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalCollection" */ "components/modal/collection/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var Login = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLogin" */ "components/modal/login/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var Delete = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalDelete" */ "components/modal/delete/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var SendFeedback = Loadable(function () { return import(/* webpackChunkName: "dynamic-SendFeedback" */ "components/modal/send-feedback/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var WordCloud = Loadable(function () { return import(/* webpackChunkName: "dynamic-WordCloud" */ "components/modal/word-cloud/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var KeyboardShortcutsModal = Loadable(function () { return import(/* webpackChunkName: "dynamic-KeyboardShortcutsModal" */ "components/modal/keyboard-shortcuts/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var PhotoResponseGroup = Loadable(function () { return import(/* webpackChunkName: "dynamic-PhotoResponseGroup" */ "components/modal/photo-response-group/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var TrendsLineInfo = Loadable(function () { return import(/* webpackChunkName: "dynamic-TrendsLineInfo" */ "components/modal/trends/line-chart/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var TrendsVisualInfo = Loadable(function () { return import(/* webpackChunkName: "dynamic-TrendsVisualInfo" */ "components/modal/trends/visual/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var WatchAVideo = Loadable(function () { return import(/* webpackChunkName: "dynamic-WatchAVideo" */ "components/modal/watch-a-video/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var AnalyticsLearnMore = loadable(function () { return import(/* webpackChunkName: "dynamic-AnalyticsLearnMore" */ "components/modal/analytics-learn-more/AnalyticsLearnMore"); }, { fallback: _jsx(LoadingComponent, {}) }).AnalyticsLearnMore;
var InviteYourTeam = Loadable(function () { return import(/* webpackChunkName: "dynamic-InviteYourTeam" */ "components/modal/invite-your-team/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var SwitchPrivateSpace = Loadable(function () { return import(/* webpackChunkName: "dynamic-SwitchPrivateSpace" */ "components/modal/switch-private-space/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var CoverageModel = Loadable(function () { return import(/* webpackChunkName: "dynamic-CoverageModel" */ "components/modal/coverage-model/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var LoginDisabled = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLoginDisabled" */ "components/modal/login-disabled/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var DateFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalDateFilter" */ "components/modal/pricing-filters/date/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var FeaturesFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFeaturesFilter" */ "components/modal/pricing-filters/features/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var LocationFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLocationFilter" */ "components/modal/pricing-filters/location/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var ProductFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalProductFilter" */ "components/modal/pricing-filters/product/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var PriceFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPriceFilter" */ "components/modal/pricing-filters/price/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var PositionFilter = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPositionFilter" */ "components/modal/pricing-filters/position/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var TextBlob = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalTextBlob" */ "components/modal/text-blob/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var EmailDomain = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalEmailDomain" */ "components/modal/email-domain/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var SuggestMissingData = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalSuggestMissingData" */ "components/modal/suggest-missing-data/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var StudioTextInput = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalStudioTextInput" */ "components/modal/studio-text-input/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var AddToCollection = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalAddToCollection" */ "components/modal/add-to-collection/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var SendAppToYourPhone = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalSendAppToYourPhone" */ "components/modal/send-app-to-your-phone/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var EditProfilePicture = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalEditProfilePicture" */ "components/modal/edit-profile-picture/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var AddPrivateCollection = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalAddPrivateCollection" */ "components/modal/add-private-collection/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var PowerpointExport = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPowerpointExport" */ "components/modal/powerpoint-export/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var Upload = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalUpload" */ "components/modal/upload/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var FlyerFilters = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFlyerFilters" */ "components/modal/flyer-filters/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var StarAView = Loadable(function () { return import(/* webpackChunkName: "dynamic-StarAView" */ "components/modal/star-a-view/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var StudioTips = Loadable(function () { return import(/* webpackChunkName: "dynamic-StudioTips" */ "components/modal/studio-tips/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var Report = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalReport" */ "components/modal/report/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var FailedUpload = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalFailedUpload" */ "components/modal/failed-upload/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var UpgradePrompt = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalUpgradePrompt" */ "components/modal/upgrade-prompt/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var PlanogramFilters = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalPlanogramFilters" */ "components/modal/planogram-filters/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var BigAnnouncement = Loadable(function () { return import(/* webpackChunkName: "dynamic-BigAnnouncement" */ "components/modal/big-announcement/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var CreateCompany = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalCreateCompany" */ "components/modal/create-company/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var SuperMario = Loadable(function () { return import(/* webpackChunkName: "dynamic-SuperMario" */ "components/modal/easter-egg-super-mario/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var MapFilters = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalMapFilters" */ "components/modal/map-filters/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var ShareMenu = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalShareMenu" */ "components/modal/share-menu/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var RemoveFromCollection = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalRemoveFromCollection" */ "components/modal/remove-from-collection/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var Share = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalShare" */ "components/modal/share/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var LinkToPage = Loadable(function () { return import(/* webpackChunkName: "dynamic-ModalLinkToPage" */ "components/modal/link-to-page/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var HawkeyeInstructions = Loadable(function () { return import(/* webpackChunkName: "dynamic-HawkeyeInstructions" */ "components/modal/hawkeye-instructions/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var DesignYourCards = Loadable(function () { return import(/* webpackChunkName: "dynamic-DesignYourCards" */ "components/modal/design-your-cards/index"); }, { fallback: _jsx(LoadingComponent, {}) });
var QuestionCode = loadable(function () { return import(/* webpackChunkName: "dynamic-QuestionCode" */ "components/question-code/QuestionCode"); }, { fallback: _jsx(LoadingComponent, {}) }).QuestionCode;
var DeleteQuestionCode = loadable(function () { return import(/* webpackChunkName: "dynamic-DeleteQuestionCode" */ "components/modal/delete-question-code/DeleteQuestionCode"); }, { fallback: _jsx(LoadingComponent, {}) }).DeleteQuestionCode;
var CreatePipelineCollection = loadable(function () { return import(/* webpackChunkName: "dynamic-CreatePipelineCollection" */ "components/modal/create-pipeline-collection/CreatePipelineCollection"); }, { fallback: _jsx(LoadingComponent, {}) }).CreatePipelineCollection;
var QuestionCodeFilters = loadable(function () { return import(/* webpackChunkName: "dynamic-QuestionCodeFilters" */ "components/modal/question-code-filters/QuestionCodeFilters"); }, { fallback: _jsx(LoadingComponent, {}) }).QuestionCodeFilters;
var AddDashboardCollection = loadable(function () { return import(/* webpackChunkName: "dynamic-AddDashboardCollection" */ "components/modal/add-dashboard-collection/AddDashboardCollection"); }, { fallback: _jsx(LoadingComponent, {}) }).AddDashboardCollection;
var InviteCollaborators = loadable(function () { return import(/* webpackChunkName: "dynamic-InviteCollaborators" */ "components/modal/invite-collaborators/InviteCollaborators"); }, { fallback: _jsx(LoadingComponent, {}) }).InviteCollaborators;
export var MODAL_COMPONENTS = {
    "ADD_DASHBOARD_COLLECTION": AddDashboardCollection,
    "ADD_TO_COLLECTION": AddToCollection,
    "ANALYTICS_LEARN_MORE": AnalyticsLearnMore,
    "BIG_ANNOUNCEMENT": BigAnnouncement,
    "POWERPOINT_EXPORT": PowerpointExport,
    "COLLECTION_SHOW_COVERAGE_MODEL": CoverageModel,
    "CREATE_COMPANY": CreateCompany,
    "CREATE_COLLECTION": Collection,
    "CREATE_NEW_PC": AddPrivateCollection,
    "CREATE_PIPELINE_COLLECTION": CreatePipelineCollection,
    "DELETE": Delete,
    "DELETE_QUESTION_CODE": DeleteQuestionCode,
    "DESIGN_YOUR_CARDS": DesignYourCards,
    "EASTER_EGG_SHOW_SUPER_MARIO": SuperMario,
    "EDIT_PROFILE_PIC": EditProfilePicture,
    "EMAIL_DOMAIN": EmailDomain,
    "FAILED_UPLOAD": FailedUpload,
    "FEED_FILTERS": FeedFilters,
    "FEED_LAYOUT_SETTINGS": FeedLayoutSettings,
    "FEED_SHARE_MENU": ShareMenu,
    "FEED_SORT_SETTINGS": FeedSort,
    "FILTER_MAP_BY_RETAILER": MapFilters,
    "FLYER_FILTERS": FlyerFilters,
    "GIVE_FEEDBACK": SendFeedback,
    "HAWKEYE_INSTRUCTIONS": HawkeyeInstructions,
    "INVITE_COLLABORATORS": InviteCollaborators,
    "LINK_TO_PAGE": LinkToPage,
    "HANDLE_INVITE_TEAM": InviteYourTeam,
    "KEYBOARD_SHORTCUTS": KeyboardShortcutsModal,
    "LOGIN": Login,
    "LOGIN_DISABLED": LoginDisabled,
    "NEW_QUESTION_CODE": QuestionCode,
    "PHOTO_RESPONSE_GROUP": PhotoResponseGroup,
    "PLANOGRAM_FILTERS": PlanogramFilters,
    "PRICING_FILTER_PRICE": PriceFilter,
    "PRICING_FILTER_FEATURES": FeaturesFilter,
    "PRICING_FILTER_POSITION": PositionFilter,
    "PRICING_FILTER_DATE": DateFilter,
    "PRICING_FILTER_LOCATION": LocationFilter,
    "PRICING_FILTER_PRODUCT": ProductFilter,
    "QUESTION_CODE_FILTERS": QuestionCodeFilters,
    "REMOVE_FROM_COLLECTION": RemoveFromCollection,
    "REPORT": Report,
    "SEND_APP_TO_YOUR_PHONE": SendAppToYourPhone,
    "SHARE": Share,
    "SHOW_VIEWER_BLOB": TextBlob,
    "STAR_A_VIEW": StarAView,
    "STARRED_VIEWS_INSTRUCTIONS": Arcade,
    "STUDIO_ADD_TEXT": StudioTextInput,
    "STUDIO_TIPS": StudioTips,
    "SUGGEST_MISSING_DATA": SuggestMissingData,
    "SWITCH_PRIVATE_SPACE": SwitchPrivateSpace,
    "TRENDS_INFO": TrendsLineInfo,
    "TRENDS_VISUALS": TrendsVisualInfo,
    "UPGRADE_FLASH": UpgradePrompt,
    "UPLOAD_PHOTOS": Upload,
    "WATCH_A_VIDEO": WatchAVideo,
    "WORD_CLOUD": WordCloud,
};
