import { Option, TooltipText, Spacer, LandingPageProfilePic } from "constants/styled-components/index";
import React, { PureComponent } from "react";
import {
  awsPrefix,
  adminPlanId,
  colorPalette, partnerCountryMapping, partnerCountries,
} from "constants/variables/index";
import { media } from "constants/responsive/index";
import { analyticsReports } from "components/analytics-reports/schema/index";
import AltCTA from "components/buttons/alternate/index";
import ToggleSwitch from "components/toggle-switch/index";
import Automation from "screens/landing-page/automation/index";
import { CirclePicker } from "react-color";
import Loadable from "react-loadable";
import Loader from "components/loader/index";
import { LoadingComponent } from "constants/functions/index";
import Selector from "components/selector/index";
import TextBox from "components/text-box/index";
import TextBoxEditable from "components/text-box-editable/index";
import UserManagement from "screens/landing-page/settings/user-management/index";
import Tooltip from "components/tooltip/index";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import { withRouter } from "services/with-router/index";
import {
  collectionCountryUpdated,
  collectionCurationUpdated,
  curatedCollectionDeleted
} from "@redux/slices/curatedCollectionsSlice";
import { getImageSource } from "shared/helpers/image";
import { syndicatedCollectionPromotionToggled } from "@redux/slices/syndicatedCollectionsSlice";
import { ChooseProfilePicture } from "screens/landing-page/settings/ChooseProfilePicture";
import { loadable } from "react-lazily/loadable";
import { userCollectionsReset } from "@redux/slices/userCollectionsSlice";

const NoAccess = Loadable({
  loader: async () =>
    import(/* webpackChunkName: "NoAccess" */ "components/no-access/index"),
  loading: LoadingComponent,
  delay: 300,
});

const { ChooseCoverPhoto } = loadable(
  () => import(/* webpackChunkName: "dynamic-ChooseCoverPhoto" */ "screens/landing-page/settings/ChooseCoverPhoto"),
  { fallback: <LoadingComponent/> }
);

const Wrapper = styled.div`
  display: flex;
  padding: 1em 2em;
  flex-wrap: wrap;
  height: 100vh;
  overflow: auto;
  justify-content: center;
  align-items: stretch;
  ${media.mobile`
      padding: 1em;
  `}
`;
const P = styled.p`
  width: 100%;
  text-align: center;
  padding-bottom: 1em;
`;
const Flex = styled.div`
  margin: 0.5em;
  background: ${(props) => (props.dark ? props.theme.grey6 : "none")};
  width: ${(props) => (props.wide ? "100%" : "auto")};
  padding-top: 1em;
  display: flex;
  min-height: 6em;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: bold;
  color: ${(props) => props.theme.grey2};
  border-radius: 0.5em;
  h3 {
    width: 100%;
  }
  .reportTitle {
    padding-bottom: 0.5em;
  }
  ${media.mobile`
    width: 100%;
  `}
`;
export const Divider = styled.div`
  width: 100%;
  padding-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  input {
    width: 100%;
    margin: 1em 3em 2em 3em;
    border: 1px solid ${(props) => props.theme.grey4};
  }
`;
const Circle = styled.div`
  width: 5em;
  height: 5em;
  border-radius: 100%;
  margin: 1em;
`;
const Input = styled.input`
  padding: 1em;
  border-radius: 3px;
  border: 2px solid ${(props) => props.theme.veryLightGrey};
  box-sizing: border-box;
  text-transform: none;
  width: 100%;
`;
const Textarea = styled.textarea`
  padding: 1em 1em 0;
  background: white;
  border-radius: 3px;
  border: 2px solid ${(props) => props.theme.veryLightGrey};
  color: grey;
  font-size: 1em;
  text-transform: none;
  box-sizing: border-box;
  width: 100%;
`;
const ColorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border-radius: 1em;
  margin: 1em;
  background: ${(props) => props.theme.veryLightGrey};
`;
const SectionTitle = styled.h3`
  padding: 2em 0.5em 0.5em 0.5em;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.theme.grey2};
`;
export const Preview = styled.img`
  width: 20em;
  max-width: 100%;
  height: 10em;
  object-fit: cover;
  object-position: top;
  border: 1px solid ${(props) => props.theme.veryLightGrey};
  border-radius: 5px;
  filter: grayscale(0.2);
`;

const CurationContainer = styled.div`
  display: flex;
  gap: 1.25em
`

class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.data.name,
      summary: this.props.data.summary,
      primary_color: this.props.data.primary_color,
      secondary_color: this.props.data.secondary_color,
    };
    this.handleProfilePicSubmit = this.handleProfilePicSubmit.bind(this);
    this.handleProfilePicSelect = this.handleProfilePicSelect.bind(this);
    this.handleColorsSubmit = this.handleColorsSubmit.bind(this);
    this.handleNameSubmit = this.handleNameSubmit.bind(this);
    this.handleCoverSelect = this.handleCoverSelect.bind(this);
    this.handleCoverSubmit = this.handleCoverSubmit.bind(this);
    this.editCollectionCategories = this.editCollectionCategories.bind(this);
    this.handleCollectionDelete = this.handleCollectionDelete.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDowngradeToggle = this.handleDowngradeToggle.bind(this);
    this.handleSummarySubmit = this.handleSummarySubmit.bind(this);
    this.cancelSummaryChange = this.cancelSummaryChange.bind(this);
    this.cancelColorChange = this.cancelColorChange.bind(this);
    this.cancelNameChange = this.cancelNameChange.bind(this);
    this.handleToggleSwitchDashboard = this.handleToggleSwitchDashboard.bind(this);
    this.cancelCategoriesIncluded = this.cancelCategoriesIncluded.bind(this);
    this.submitCategoriesIncluded = this.submitCategoriesIncluded.bind(this);
    this.handleCuratedCollectionToggle = this.handleCuratedCollectionToggle.bind(this);
    this.handlePromotedSyndicatedCollectionToggle = this.handlePromotedSyndicatedCollectionToggle.bind(this);
    this.handleCollectionCountryChange = this.handleCollectionCountryChange.bind(this);
  }

  componentDidMount() {
    this.props.setData({
      type: "HIDE_HEADER",
    });
    if (!this.props.dataInitialLoad) {
      this.props.apiCall({
        method: "GET",
        component: "INITIAL_LOAD",
        route: "search/initial_load/",
      });
    }
    if (this.props.dataUser?.plan_id === adminPlanId && !this.props.syndicatedCollections.length) {
      this.props.apiCall({
        method: "GET",
        component: "SYNDICATED_COLLECTIONS",
        route: `admin/syndicated_collections`
      });
    }
  }
  handleDowngradeToggle() {
    this.props.setData({
      type: "PRIVATE_SPACE_TOGGLE_DOWNGRADE",
      data: this.props.data.downgraded ? false : new Date(),
    });
    this.props.apiCall({
      method: "POST",
      component: "PRIVATE_COLLECTION_CHANGE_DOWNGRADE",
      body: {
        user_id: this.props.dataUser.id,
        landing_page_slug: this.props.data.url_slug,
        downgrade: this.props.data.downgraded ? false : true,
      },
      route: "private_collection/change/downgrade",
    });
  }
  editCollectionCategories(e) {
    this.setState({
      includedCategories: e.data.map((x) => x.id),
    });
  }

  submitCategoriesIncluded() {
    this.props.setData({
      type: "SET_INCLUDED_CATEGORIES_FOR_ANALYTICS",
      data: this.state.includedCategories,
    });
    this.props.apiCall({
      method: "POST",
      component: "SAVE_INCLUDED_CATEGORIES",
      body: {
        user_id: this.props.dataUser.id,
        landing_page_slug: this.props.data.url_slug,
        categories: this.state.includedCategories,
      },
      route: "collection/included_categories",
    });
  }

  cancelCategoriesIncluded() {
    this.setState({
      includedCategories: null,
    });
  }

  handleProfilePicSubmit() {
    const payload = {
      img: this.state.profilePicImg,
      source: this.state.profilePicSource,
      landing_page_slug: this.props.data.url_slug
    };
    this.props.setData({
      type: "CHANGE_LANDING_PAGE_PROFILE_PIC",
      data: payload
    });
    this.props.apiCall({
      method: "POST",
      component: "SAVE_NEW_PROFILE_PIC",
      route: "collection/change/profile_pic",
      body: payload
    });
  }

  handleProfilePicSelect(img, source) {
    this.setState({
      profilePicImg: img,
      profilePicSource: source,
    });
  }

  cancelNameChange() {
    this.setState({
      name: this.props.data.name,
    });
  }

  cancelSummaryChange() {
    this.setState({
      summary: this.props.data.summary,
    });
  }

  cancelColorChange() {
    this.setState({
      primary_color: this.props.data.primary_color,
      secondary_color: this.props.data.secondary_color,
    });
  }

  handleColorChange(field, event) {
    const rgb = `(${event.rgb.r},${event.rgb.g},${event.rgb.b})`;
    this.setState({
      [`${field}_as_hex`]: event.hex,
      [field]: rgb,
    });
  }
  handleColorsSubmit() {
    if (
      this.state.primary_color !== this.props.data.primary_color ||
      this.state.secondary_color !== this.props.data.secondary_color
    ) {
      this.props.setData({
        type: "CHANGE_LANDING_PAGE_COLORS",
        data: {
          primary_color: this.state.primary_color,
          secondary_color: this.state.secondary_color,
          landing_page_slug: this.props.data.url_slug,
        },
      });
      this.props.apiCall({
        method: "POST",
        component: this.props.private
          ? "PRIVATE_COLLECTION_CHANGE_COLORS"
          : "COLLECTION_CHANGE_COLORS",
        body: {
          user_id: this.props.dataUser.id,
          landing_page_slug: this.props.data.url_slug,
          primary_color: this.state.primary_color,
          secondary_color: this.state.secondary_color,
        },
        route: this.props.private
          ? "private_collection/change/colors"
          : "collection/change/colors",
      });
    }
  }

  handleFieldChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleNameSubmit() {
    if (this.state.name !== this.props.data.name) {
      this.props.setData({
        type: "CHANGE_LANDING_PAGE_NAME",
        data: {
          name: this.state.name,
          landing_page_slug: this.props.data.url_slug,
        },
      });
      this.props.apiCall({
        method: "POST",
        component: this.props.private
          ? "PRIVATE_COLLECTION_CHANGE_NAME"
          : "COLLECTION_CHANGE_NAME",
        body: {
          user_id: this.props.dataUser.id,
          landing_page_slug: this.props.data.url_slug,
          name: this.state.name,
        },
        route: this.props.private
          ? "private_collection/change/name"
          : "collection/change/name",
      });
    }
  }

  handleToggleSwitchDashboard() {
    this.props.setData({
      type: "PRIVATE_COLLECTION_TOGGLE_SHOW_DASHBOARD",
    });
    this.props.apiCall({
      method: "POST",
      component: "PRIVATE_COLLECTION_TOGGLE_SHOW_DASHBOARD_API",
      body: {
        landing_page_slug: this.props.data.url_slug,
      },
      route: "private_collection/settings/dashboard",
    });
  }
  handleSummarySubmit() {
    if (this.state.summary !== this.props.data.summary) {
      this.props.setData({
        type: "CHANGE_LANDING_PAGE_SUMMARY",
        data: this.state.summary,
      });
      this.props.apiCall({
        method: "POST",
        component: this.props.private
          ? "PRIVATE_COLLECTION_CHANGE_SUMMARY"
          : "COLLECTION_CHANGE_SUMMARY",
        body: {
          user_id: this.props.dataUser.id,
          landing_page_slug: this.props.data.url_slug,
          summary: this.state.summary,
        },
        route: this.props.private
          ? "private_collection/change/summary"
          : "collection/change/summary",
      });
    }
  }
  handleCoverSelect(selectedCover) {
    this.setState({ selectedCover });
  }

  handleCoverSubmit() {
    const { selectedCover } = this.state;
    if (!selectedCover) {
      return;
    }
    this.setState({ selectedCover: null });

    if (typeof selectedCover === "string") { // Photo URL from Unsplash
      if (selectedCover !== this.props.data.cover_url) {
        this.props.setData({
          type: "CHANGE_LANDING_PAGE_COVER",
          data: {
            cover: selectedCover,
            landing_page_slug: this.props.data.url_slug,
          },
        });
        this.props.apiCall({
          method: "POST",
          component: this.props.private
            ? "PRIVATE_COLLECTION_CHANGE_COVER"
            : "COLLECTION_CHANGE_COVER",
          body: {
            landing_page_slug: this.props.data.url_slug,
            cover_url: selectedCover,
          },
          route: this.props.private
            ? "private_collection/change/cover"
            : "collection/change/cover",
        });
      }
    } else {
      this.handleCoverUpload(selectedCover)
    }
  }

  handleCoverUpload(file) {
    this.props.setData({
      type: "CHANGE_LANDING_PAGE_COVER",
      data: {
        cover: file.preview,
        landing_page_slug: this.props.data.url_slug,
      },
    });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.props.apiCall({
        method: "POST",
        component: this.props.private
          ? "PRIVATE_COLLECTION_UPLOAD_COVER"
          : "COLLECTION_UPLOAD_COVER",
        body: {
          landing_page_slug: this.props.data.url_slug,
          file: reader.result.split(",")[1],
        },
        route: this.props.private
          ? "private_collection/upload/cover"
          : "collection/upload/cover",
      });
    };
  }

  handleCuratedCollectionToggle() {
    const new_is_curated = !this.props.data.is_curated;
    this.props.dispatch(collectionCurationUpdated({
      ...this.props.data,
      is_curated: new_is_curated
    }));
    this.props.apiCall({
      method: "POST",
      route: "collection/change/curation",
      component: "COLLECTION_TOGGLE_CURATION",
      body: {
        landing_page_slug: this.props.data.url_slug,
        is_curated: new_is_curated
      },
    });
  }

  handlePromotedSyndicatedCollectionToggle() {
    this.props.dispatch(syndicatedCollectionPromotionToggled(
      this.props.data
    ));
    this.props.apiCall({
      method: "POST",
      component: "SYNDICATED_COLLECTION_PROMOTION_CHANGE",
      route: `admin/syndicated_collections/promoted/update`,
      body: {
        action: this.props.data.is_promoted ? "remove" : "add",
        collection_id: this.props.data.url_slug
      }
    });
  }

  handleCollectionCountryChange(countryId) {
    this.props.dispatch(collectionCountryUpdated({
      id: this.props.data.id,
      countryId: countryId
    }));
    this.props.apiCall({
      method: "POST",
      route: "collection/change/country",
      component: "COLLECTION_CHANGE_COUNTRY",
      body: {
        landing_page_slug: this.props.data.url_slug,
        country_id: countryId
      },
    });
  }

  handleCollectionDelete() {
    this.props.dispatch(userCollectionsReset());
    this.props.apiCall({
      method: "POST",
      component: "COLLECTION_DELETE",
      body: {
        user_id: this.props.dataUser.id,
        landing_page_slug: this.props.data.url_slug,
      },
      route: "collection/delete",
    });
    if (this.props.data.is_curated) {
      this.props.dispatch(curatedCollectionDeleted(this.props.data.url_slug))
    }
    setTimeout(() => {
      this.props.navigate("/collections/personal");
    }, 500);
  }
  toggleReportExclusion(report) {
    const include = !!this.props.dataLandingPageAnalytics?.excluded_reports?.includes(report)
    this.props.setData({
      type: include ? "ANALYTICS_REPORT_INCLUDE" : "ANALYTICS_REPORT_EXCLUDE",
      data: report,
    });
    this.props.apiCall({
      method: "POST",
      component: include
        ? "ANALYTICS_REPORT_INCLUDE_API"
        : "ANALYTICS_REPORT_EXCLUDE_API",
      body: {
        user_id: this.props.dataUser.id,
        report: report,
        collection_id: this.props.data.id,
        include: include,
      },
      route: "collection/analytics/reports/exclusion",
    });
  }

  render() {
    if (!this.props.dataLandingPageAnalytics) {
      return <Loader />;
    } else if (
      this.props.dataLandingPageAnalytics &&
      !this.props.dataLandingPageAnalytics.is_admin
    ) {
      return <NoAccess />;
    }
    return (
      <Wrapper>
        {this.props.fetchReducer &&
        this.props.fetchReducer.component == "COLLECTION_DELETE" ? (
          <Divider>
            <P>Deleting this collection</P>
            <Loader />
          </Divider>
        ) : this.props.data ? (
          <>
            <SectionTitle>Details</SectionTitle>
            <TextBoxEditable
              title="Name"
              handleCancel={this.cancelNameChange}
              handleSubmit={this.handleNameSubmit}
              disabledSave={
                !this.state.name ||
                this.state.name.length < 5 ||
                this.state.name.length > 30 ||
                this.state.name == this.props.data.name
              }
              formFields={
                <Input
                  type="text"
                  name="name"
                  maxLength={30}
                  style={{
                    borderColor:
                      this.state.name && this.state.name.length > 29
                        ? "darkred"
                        : "whitesmoke",
                  }}
                  value={this.state.name}
                  onChange={this.handleFieldChange}
                />
              }
              text={
                <Divider>
                  <P>{this.state.name}</P>
                </Divider>
              }
            />
            <TextBoxEditable
              title="Summary"
              handleCancel={this.cancelSummaryChange}
              handleSubmit={this.handleSummarySubmit}
              disabledSave={
                !this.state.summary ||
                this.state.summary.length < 3 ||
                this.state.summary == this.props.data.summary
              }
              formFields={
                <Textarea
                  rows="8"
                  type="text"
                  name="summary"
                  value={this.state.summary}
                  onChange={this.handleFieldChange}
                />
              }
              text={
                <Divider>
                  <P>{this.state.summary}</P>
                </Divider>
              }
            />
            <TextBoxEditable
              title="Colors"
              handleCancel={this.cancelColorChange}
              handleSubmit={this.handleColorsSubmit}
              formFields={
                <Divider>
                  <ColorContainer>
                    <P>Primary Color</P>
                    <CirclePicker
                      color={
                        this.state.primary_color_as_hex
                          ? this.state.primary_color_as_hex
                          : "transparent"
                      }
                      colors={colorPalette}
                      onChange={this.handleColorChange.bind(
                        this,
                        "primary_color"
                      )}
                    />
                  </ColorContainer>
                  <ColorContainer>
                    <P>Secondary Color</P>
                    <CirclePicker
                      color={
                        this.state.secondary_color_as_hex
                          ? this.state.secondary_color_as_hex
                          : "transparent"
                      }
                      colors={colorPalette}
                      onChange={this.handleColorChange.bind(
                        this,
                        "secondary_color"
                      )}
                    />
                  </ColorContainer>
                </Divider>
              }
              text={
                <>
                  <Divider>
                    <Circle
                      style={{
                        background: "rgb" + this.state.primary_color,
                      }}
                    />
                    <Circle
                      style={{
                        background: "rgb" + this.state.secondary_color,
                      }}
                    />
                  </Divider>
                </>
              }
            />
            {this.props.page === "collection" && (
              <TextBoxEditable
                title="Profile pic"
                handleSubmit={this.handleProfilePicSubmit}
                text={
                  <LandingPageProfilePic
                    selected
                    img={this.props.data.img}
                    source={this.props.data.source || "collections"}
                  />
                }
                formFields={
                  <Divider>
                    <ChooseProfilePicture
                      currentImg={this.props.data.img}
                      onSelect={this.handleProfilePicSelect}
                    />
                  </Divider>
                }
              />
            )}
            <TextBoxEditable
              title="Cover photo"
              handleSubmit={this.handleCoverSubmit}
              handleCancel={() => this.handleCoverSelect(null)}
              text={
                <>
                  <Divider>
                    {this.props.data.cover_url ? (
                      <Preview src={this.props.data.cover_url} />
                    ) : this.props.data.background ? (
                      <Preview src={`${awsPrefix}lp_backgrounds/${this.props.data.background}`}/>
                    ) : (
                      <P>No background image set</P>
                    )}
                  </Divider>
                </>
              }
              formFields={
                <ChooseCoverPhoto
                  onCoverSelect={this.handleCoverSelect}
                  currentCoverUrl={this.props.data.cover_url}
                  initialQuery={this.props.data.company || this.props.data.name}
                />
              }
            />
            {this.props.page === "collection" && this.props.dataUser?.plan_id === adminPlanId && (
                <>
                  {this.props.data.is_user_owned && (
                    <TextBox
                      title="Make this a curated collection"
                      text={
                        <CurationContainer>
                          <ToggleSwitch
                            wrapperWidth="10em"
                            toggledOn={!!this.props.data.is_curated}
                            clickAction={this.handleCuratedCollectionToggle}
                          />
                          {this.props.data.is_curated && (
                            <Selector
                              portal
                              isSingle
                              minWidth="20em"
                              selectorType="countries"
                              defaultValue={[{
                                ...partnerCountryMapping[this.props.data.country_id],
                                value: this.props.data.country_id,
                                type: "countries"
                              }]}
                              options={partnerCountries.map(country => ({
                                ...country,
                                value: country.id,
                                label: (
                                  <Option>
                                    <img
                                      src={getImageSource(country)}
                                      alt={country.name}
                                    />
                                    {country.name}
                                  </Option>
                                )
                              }))}
                              clickAction={(e) => this.handleCollectionCountryChange(e.data.value)}
                            />
                          )}
                        </CurationContainer>
                      }
                    />
                  )}
                  {this.props.syndicatedCollections.find(collection => collection.id === this.props.data.id) && (
                    <TextBox
                      title="Make this a promoted syndicated collection"
                      text={
                        <ToggleSwitch
                          wrapperWidth="10em"
                          toggledOn={!!this.props.data.is_promoted}
                          clickAction={this.handlePromotedSyndicatedCollectionToggle}
                        />
                      }
                    />
                  )}
                </>
              )
            }
            {this.props.page == "private_collection" ? (
              <>
                <TextBox
                  title="Show a dashboard with shortcuts to relevant categories, channels, and more"
                  text={
                    <ToggleSwitch
                      toggledOn={this.props.data.show_dashboard}
                      clickAction={this.handleToggleSwitchDashboard}
                    />
                  }
                />
                {this.props.dataUser &&
                this.props.dataUser.plan_id === adminPlanId ? (
                  <TextBox
                    title="Downgrade this private space to the free plan"
                    text={
                      <ToggleSwitch
                        toggledOn={this.props.data.downgraded}
                        clickAction={this.handleDowngradeToggle}
                      />
                    }
                  />
                ) : null}
              </>
            ) : null}
            {this.props.page == "private_collection" ? (
              <>
                <SectionTitle>Automation</SectionTitle>
                <Automation
                  private
                  page="private collection"
                  data={this.props.data}
                />
                <SectionTitle>User management</SectionTitle>
                <UserManagement data={this.props.data} />
              </>
            ) : null}

            {this.props.page === "collection" &&
            this.props.dataInitialLoad?.categories?.length &&
            this.props.data.is_pipeline && (
              <>
                <SectionTitle>Analytics</SectionTitle>
                <TextBoxEditable
                  title="Categories to include"
                  text={this.props.dataInitialLoad.categories
                    .filter(category =>
                      this.props.dataLandingPageAnalytics.included_categories?.includes(category.id)
                    )
                    .map(category => (
                      <Divider key={`included-cat-${category.id}`}>
                        <p>{category.name}</p>
                      </Divider>
                    ))}
                  handleCancel={this.cancelCategoriesIncluded}
                  handleSubmit={this.submitCategoriesIncluded}
                  formFields={
                    <Selector
                      selectorType="categories"
                      wrapValues
                      portal
                      minWidth="24em"
                      component="COLLECTION_ANALYTICS_SET_CATEGORIES"
                      placeholder="Select categories..."
                      clickAction={this.editCollectionCategories}
                      defaultValue={this.props.dataInitialLoad.categories
                        .filter(category =>
                          this.props.dataLandingPageAnalytics.included_categories?.includes(category.id)
                        )
                        .map(item => ({
                          type: "categories",
                          value: item.name,
                          img: item.img,
                          id: item.id,
                          label: (
                            <Option>
                              <img src={`${awsPrefix}categories/${item.img}`} />
                              {item.name}
                            </Option>
                          ),
                        }))}
                    />
                  }
                />
                <TextBox
                  title="Reports"
                  hideSave
                  text={
                    <Divider>
                      {analyticsReports.map((y, index) => (
                        <Flex key={`analytics-${index}-${y.slug}`} wide>
                          <h3>{y.name}</h3>
                          {y.reports.map((x, i) => (
                            <Flex key={`report-${i}-${index}-${x.slug}`} dark>
                              <Tooltip
                                unmountHTMLWhenHide
                                html={<TooltipText>{x.name}</TooltipText>}
                                position="top"
                              >
                                <span>{x.name}</span>
                              </Tooltip>
                              <ToggleSwitch
                                wrapperWidth="100%"
                                toggledOn={
                                  !this.props.dataLandingPageAnalytics.excluded_reports?.includes(x.slug)
                                }
                                clickAction={this.toggleReportExclusion.bind(
                                  this,
                                  x.slug
                                )}
                              />
                            </Flex>
                          ))}
                        </Flex>
                      ))}
                    </Divider>
                  }
                />
              </>
            )}
            {this.props.page === "collection" ? (
              <>
                <SectionTitle>Delete</SectionTitle>
                <TextBoxEditable
                  title="Delete"
                  hideSave
                  text={
                    <Divider>
                      <P>
                        Deleting the collection will remove it from the
                        Shelfgram platform
                      </P>
                    </Divider>
                  }
                  formFields={
                    <Divider>
                      <P>
                        Deleting the collection will remove it from the
                        Shelfgram platform
                      </P>
                      <AltCTA
                        text="Delete this collection"
                        type="button"
                        clickAction={this.handleCollectionDelete}
                      />
                    </Divider>
                  }
                />
              </>
            ) : null}
          </>
        ) : (
          <Loader />
        )}
        <Spacer />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  automationRules: state.automationRules,
  dataInitialLoad: state.dataInitialLoad,
  dataLandingPageAnalytics: state.dataLandingPageAnalytics,
  dataPreviewPhotoOptions: state.dataPreviewPhotoOptions,
  fetchReducer: state.fetchReducer,
  syndicatedCollections: state.syndicatedCollections,
  dataUser: state.dataUser,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
});

export default withRouter(
  withTheme(connect(mapStateToProps, mapDispatchToProps)(Settings))
);
