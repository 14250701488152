import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from "@redux/hooks";
import React, { useEffect, useState } from "react";
import { emptyArray } from "constants/variables";
import { TooltipText, LandingPageProfilePic } from "constants/styled-components";
import { useApiCall } from "hooks/useApiCall";
import Tooltip from "components/tooltip";
export var ChooseProfilePicture = function (_a) {
    var currentImg = _a.currentImg, onSelect = _a.onSelect;
    var initialLoadCategories = useAppSelector(function (state) { var _a, _b; return ((_b = (_a = state.dataInitialLoad) === null || _a === void 0 ? void 0 : _a.categories) !== null && _b !== void 0 ? _b : emptyArray); });
    var _b = useState(currentImg), selectedImg = _b[0], setSelectedImg = _b[1];
    var apiCall = useApiCall("INITIAL_LOAD").apiCall;
    useEffect(function () {
        if (!initialLoadCategories.length) {
            apiCall({
                method: "GET",
                component: "INITIAL_LOAD",
                route: "search/initial_load/",
            });
        }
    }, []);
    var handleProfilePicSelect = function (img, source) {
        setSelectedImg(img);
        onSelect(img, source);
    };
    return !!initialLoadCategories.length && (initialLoadCategories.map(function (_a) {
        var id = _a.id, name = _a.name, img = _a.img, source = _a.source;
        return (_jsx(Tooltip, { position: "top", width: "auto", html: _jsx(TooltipText, { children: name }), unmountHTMLWhenHide: true, children: _jsx(LandingPageProfilePic, { onClick: function () { return handleProfilePicSelect(img, source); }, selected: img === selectedImg, source: source, img: img, tabIndex: 0 }) }, id));
    }));
};
