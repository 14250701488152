var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createSelector } from "@reduxjs/toolkit";
import { selectCuratedCollections } from "@redux/slices/curatedCollectionsSlice";
import { selectUserCollections } from "@redux/slices/userCollectionsSlice";
export var selectVisibleCollections = createSelector([selectCuratedCollections, selectUserCollections], function (curatedCollections, userCollections) {
    return __spreadArray(__spreadArray([], curatedCollections, true), userCollections.filter(function (collection) { return !collection.is_curated; }), true);
});
export var selectVisibleCollectionIds = createSelector([selectVisibleCollections], function (visibleCollections) { return visibleCollections.map(function (collection) { return collection.id; }); });
