import { PureComponent } from "react";
import Loader from "components/loader/index";
import TextBox from "components/text-box/index";
import { clearbitUrl, emptyArray } from "constants/variables/index";
import ToggleSwitch from "components/toggle-switch/index";
import AltCTA from "components/buttons/alternate/index";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";

const Divider = styled.div`
  width: 100%;
  padding-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  input {
    width: 100%;
    margin: 1em 3em 2em 3em;
    border: 1px solid ${(props) => props.theme.grey4};
  }
`;
const P = styled.p`
  width: 100%;
  text-align: center;
  padding-bottom: 1em;
`;
const SectionTitle = styled.h3`
  padding: 2em 0.5em 0.5em 0.5em;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: ${(props) => props.theme.grey2};
`;
const CompanyLogo = styled.div`
  width: 3em;
  height: 3em;
  border-radius: 0.5em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  border: 1px solid ${(props) => props.theme.grey4};
`;

class UserManagement extends PureComponent {
  constructor(props) {
    super(props);
    this.openEmailDomainModal = this.openEmailDomainModal.bind(this);
  }
  openEmailDomainModal() {
    this.props.showModal({ component: Modals.EMAIL_DOMAIN });
  }
  handleToggleSwitchDomains(x) {
    const newWhitelisted =
      this.props.dataLandingPageAnalytics.whitelisted_emails.map((y) => {
        if (y.domain == x.domain) {
          return {
            ...y,
            active: !y.active,
          };
        } else {
          return y;
        }
      });
    this.props.setData({
      type: "UPDATE_WHITELISTED_EMAILS",
      data: newWhitelisted,
    });
    this.props.apiCall({
      method: "POST",
      component: "REMOVE_PREAPPROVED_EMAIL_DOMAIN",
      body: {
        user_id: this.props.dataUser.id,
        active: !x.active,
        preapproved_id: x.preapproved_id,
        domain: x.domain,
      },
      route: `private_collection/id/${this.props.data.url_slug}/whitelist`,
    });
  }
  removeDomain(domain) {
    this.props.setData({
      type: "REMOVE_PREAPPROVED_EMAIL_DOMAIN_LOCALLY",
      data: domain.preapproved_id,
    });
    this.props.apiCall({
      method: "POST",
      component: "REMOVE_PREAPPROVED_EMAIL_DOMAIN",
      body: {
        user_id: this.props.dataUser.id,
        preapproved_id: domain.preapproved_id,
      },
      route: `private_collection/id/${this.props.data.url_slug}/remove`,
    });
  }
  render() {
    const whitelistedEmails = this.props.dataLandingPageAnalytics?.whitelisted_emails
      ?.map((z) => ({
        ...z,
        num_users:
          this.props.dataLandingPageAbout?.collection_users?.filter((y) =>
            y.email.toLowerCase().includes(`@${z.domain}`.toLowerCase())
          ).length || 0
      }))
      .sort((a, b) => b.num_users - a.num_users) ?? emptyArray;
    return (
      <TextBox
        title="Allowlisted email domains"
        size="full"
        text={
          this.props.fetchReducer.fetching &&
          this.props.fetchReducer.component ===
            "REMOVE_PREAPPROVED_EMAIL_DOMAIN" ? (
            <Loader />
          ) : (
            <Divider>
              <P>
                {`Allowlist email domains to automatically
                        approve users that request access using their work email to the ${this.props.dataLandingPageAbout.name} private space.`}
              </P>
              {whitelistedEmails.map((x, i) => (
                <Divider key={`${i}-${x.preapproved_id}-email-domain`}>
                  <SectionTitle
                    style={{
                      width: "auto",
                      padding: "0 1em 0 0",
                      margin: "0",
                    }}
                  >
                    <CompanyLogo
                      style={{
                        backgroundImage: `url("${clearbitUrl}${x.domain}")`,
                      }}
                    />
                  </SectionTitle>
                  <SectionTitle
                    style={{
                      textAlign: "left",
                      padding: "0.5em",
                      width: "15em",
                    }}
                  >
                    {`@${x.domain}`}
                  </SectionTitle>
                  <SectionTitle
                    style={{
                      padding: "0.5em",
                      width: "7em",
                    }}
                  >
                    {x.num_users}
                  </SectionTitle>
                  <ToggleSwitch
                    toggledOn={x.active}
                    clickAction={this.handleToggleSwitchDomains.bind(this, x)}
                  />
                  <AltCTA
                    text="Remove"
                    backgroundHover={this.props.theme.midRed}
                    clickAction={this.removeDomain.bind(this, x)}
                  />
                </Divider>
              ))}
              <AltCTA
                text="Add a domain"
                dark
                clickAction={this.openEmailDomainModal}
              />
            </Divider>
          )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  dataLandingPageAbout: state.dataLandingPageAbout,
  dataLandingPageAnalytics: state.dataLandingPageAnalytics,
  dataUser: state.dataUser,
  fetchReducer: state.fetchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  showModal: (payload) => dispatch(showModal(payload))
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(UserManagement)
);
