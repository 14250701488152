import { PureComponent } from "react";
import { awsPrefix, emptyArray } from "constants/variables/index";

import FilterRules from "components/feed/filter-rules/index";
import Loadable from "react-loadable";
import Loader from "components/loader/index";
import { LoadingComponent } from "constants/functions/index";
import TextBoxEditable from "components/text-box-editable/index";
import { connect } from "react-redux";
import { inspect } from "util";
import { media } from "constants/responsive/index";
import styled from "styled-components";

const NoAccess = Loadable({
  loader: async () =>
    import(/* webpackChunkName: "NoAccess" */ "components/no-access/index"),
  loading: LoadingComponent,
  delay: 300,
});
const P = styled.p`
  width: 100%;
  color: ${(props) => props.theme.grey3} !important;
  font-weight: bold;
  text-align: center;
  padding-bottom: 3em;
`;

const Img = styled.img`
  max-width: 750px;
  animation: fadeIn 1s;
  ${media.mobile`
    max-width: 100%;
  `}
`;

const Divider = styled.div`
  width: 100%;
  padding-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  input {
    width: 100%;
    margin: 1em 3em 2em 3em;
    border: 1px solid ${(props) => props.theme.grey4};
  }
`;

class Automation extends PureComponent {
  constructor(props) {
    super(props);
    this.cancelRuleChanges = this.cancelRuleChanges.bind(this);
    this.handleRulesSubmit = this.handleRulesSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.props.automationRules.loaded) {
      this.props.apiCall({
        method: "GET",
        component: "AUTOMATION_RULES",
        route: this.props.private
          ? `private_collection/id/${this.props.data.id}/rule`
          : `collection/id/${this.props.data.url_slug}/rule`,
      });
    }
  }

  cancelRuleChanges() {
    this.props.setData({
      type: "RESET_AUTOMATION_RULES",
    });
  }

  handleRulesSubmit() {
    if (
      (this.props.automationRules.new.filter &&
        this.props.automationRules.new.filter.length == 0 &&
        this.props.automationRules.existing &&
        this.props.automationRules.existing.filter.length == 0) ||
      inspect(this.props.automationRules.existing, { depth: null }) ===
        inspect(this.props.automationRules.new, { depth: null })
    ) {
      return;
    }
    this.props.setData({
      type: "AUTOMATION_CONDITION_SAVE_NEW",
    });
    this.props.apiCall({
      method: "POST",
      component: "AUTOMATION_RULES_SAVE_TO_API",
      body: {
        user_id: this.props.dataUser.id,
        action:
          this.props.automationRules.new.filter.length == 0
            ? "delete"
            : "update",
        json_data: this.props.automationRules.new,
        query_data: this.props.automationRules.new.filter.map((rule) =>
          rule.map((condition) => ({
            is: condition.is,
            match: condition.match,
            startDate: condition.startDate,
            endDate: condition.endDate,
            ids: condition.item && condition.item.map((item) => item.id),
          }))
        ),
      },
      route: this.props.private
        ? `private_collection/id/${this.props.data.id}/rule`
        : `collection/id/${this.props.data.url_slug}/rule`,
    });
  }

  render() {
    if (!this.props.dataLandingPageAnalytics) {
      return <Loader />;
    } else if (
      this.props.dataLandingPageAnalytics &&
      !this.props.dataLandingPageAnalytics.is_admin
    ) {
      return <NoAccess />;
    }
    return (
      <TextBoxEditable
        title="Matching rules"
        tooltip={
          this.props.automationRules.noRules ||
          this.props.automationRules.new.filter.length == 0
            ? null
            : `Shelfgram will update this ${this.props.page} as photos are added, removed, or changed that match your criteria.`
        }
        handleCancel={this.cancelRuleChanges}
        handleSubmit={this.handleRulesSubmit}
        formFields={<FilterRules settings />}
        disabledSave={
          this.props.automationRules &&
          this.props.automationRules.new &&
          this.props.automationRules.new.filter
            ? this.props.automationRules.new.filter.length == 0 &&
              !this.props.automationRules.existing
              ? true
              : this.props.automationRules.new.filter
                  .reduce((acc, val) => acc.concat(val), emptyArray)
                  .map((condition) => condition.item)
                  .includes(null) ||
                this.props.automationRules.new.filter
                  .reduce((acc, val) => acc.concat(val), emptyArray)
                  .map(
                    (x) =>
                      x.match == "dates" &&
                      x.item &&
                      x.item[0].id == "custom" &&
                      (!x.endDate || !x.startDate)
                  )
                  .includes(true)
            : true
        }
        text={
          <Divider>
            {this.props.fetchReducer.fetching &&
            this.props.fetchReducer.component == "AUTOMATION_RULES" ? (
              <Loader />
            ) : this.props.automationRules.new &&
              this.props.automationRules.new.filter &&
              this.props.automationRules.new.filter.length > 0 ? (
              <FilterRules settings static />
            ) : this.props.automationRules.noRules ||
              this.props.automationRules.new.filter.length == 0 ? (
              <>
                <P>
                  {`Set up matching rules to automatically add retail photos to your ${this.props.page}. Shelfgram will update this ${this.props.page} as photos are added, removed, or changed that match your criteria.`}
                </P>
                <Img
                  src={`${awsPrefix}web-app/filters.svg`}
                  draggable="false"
                />
              </>
            ) : (
              <Loader />
            )}
          </Divider>
        }
      />
    );
  }
}
const mapStateToProps = (state) => ({
  fetchReducer: state.fetchReducer,
  dataLandingPageAnalytics: state.dataLandingPageAnalytics,
  automationRules: state.automationRules,
  dataUser: state.dataUser,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Automation);
