import { createListenerMiddleware, addListener } from '@reduxjs/toolkit';
export var listenerMiddleware = createListenerMiddleware();
export var startAppListening = listenerMiddleware.startListening.withTypes();
export var addAppListener = addListener.withTypes();
// Register Listeners
import { addModalListeners } from "@redux/slices/modalSlice";
import { addPrefetchedDataListener } from "@redux/slices/feedFilterRulesSlice";
import { addAutocompleteSuggestionsListener } from "@redux/slices/autocompleteSuggestionsSlice";
addModalListeners(startAppListening);
addPrefetchedDataListener(startAppListening);
addAutocompleteSuggestionsListener(startAppListening);
