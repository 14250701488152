// Type Guards
export var isCuratedCollection = function (collection) {
    return collection.is_curated;
};
export var isRetailPipeline = function (collection) {
    return collection.is_pipeline;
};
export var isCustomCollection = function (collection) {
    return !!collection.custom_type_name;
};
export var isOwnedCollection = function (collection) {
    return "is_admin" in collection && collection.is_admin;
};
export var isCollaborativeCollection = function (collection) {
    return collection.is_admin !== undefined && !collection.is_admin;
};
