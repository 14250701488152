import { connect } from "react-redux";
import {
  FaFlag,
  FaFilePowerpoint,
  FaFolderPlus,
  FaCog,
  FaSitemap,
  FaPlus,
  FaCheck,
  FaFolderMinus,
  FaTrash,
} from "react-icons/fa";
import Loader from "components/loader/index";
import UpgradeIcon from "components/upgrade-icon/index";
import { validPlanIdsForDelete } from "constants/variables";
import { generatePowerpoint } from "services/download-powerpoint/photos";
import { PureComponent } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";
import { asArray } from "shared/helpers/asArray";
import { selectUserCollectionById } from "@redux/slices/userCollectionsSlice";

const Layer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  animation: fadeIn 0.5s;
  background: rgba(0, 0, 0, 0.5);
`;
const Item = styled.li`
  list-style-type: none;
  padding: 0.75em 1em;
  cursor: pointer;
  display: flex;
  font-size: 0.8em;
  font-weight: bold;
  align-items: center;
  color: ${(props) => props.theme.grey2};
  transition: ${(props) => props.theme.defaultTransition};
  .upgradeIcon {
    margin-left: 0.5em;
  }
  svg {
    transition: ${(props) => props.theme.defaultTransition};
    margin-right: 0.5em;
    height: 1em;
    width: 1em;
    fill: ${(props) => props.theme.grey2};
  }
  &:hover {
    background: ${(props) => props.theme.grey6};
    color: ${(props) => (props.red ? props.theme.midRed : props.theme.grey1)};
    svg {
      fill: ${(props) => (props.red ? props.theme.midRed : props.theme.grey1)};
    }
  }
`;
const Menu = styled.ul`
  padding: 0.5em;
  border-radius: 0.5em;
  box-shadow: ${(props) => props.theme.sortOfSubtleBoxShadow};
  margin: 0;
  position: absolute;
  background: ${(props) => props.theme.white};
  z-index: 7;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -0.5em;
    right: 20%;
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-top: 0.5em solid ${(props) => props.theme.white};
  }
`;

class MoreMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      preparingSlides: false,
    };
    this.el = document.createElement("div");
    this.handleDownloadSlide = this.handleDownloadSlide.bind(this);
    this.handleDropdownReport = this.handleDropdownReport.bind(this);
    this.handleEnterpriseGate = this.handleEnterpriseGate.bind(this);
    this.handleDropdownSettings = this.handleDropdownSettings.bind(this);
    this.handleDropdownDelete = this.handleDropdownDelete.bind(this);
    this.handleDropdownRemoveFromCollection =
      this.handleDropdownRemoveFromCollection.bind(this);
    this.handleDropdownAddToCollection =
      this.handleDropdownAddToCollection.bind(this);
    this.unsave = this.unsave.bind(this);
    this.save = this.save.bind(this);
  }
  handleEnterpriseGate() {
    this.props.showModal({ component: Modals.UPGRADE_FLASH });
    this.props.hideMoreMenu();
  }
  handleDownloadSlide() {
    this.setState(
      {
        preparingSlides: true,
      },
      () =>
        generatePowerpoint(
          [this.props.data],
          this.props.isFlyer ? "flyers" : "photos",
          this.props.isFlyer ? "Flyer from Shelfgram" : "Photo from Shelfgram",
          this.props.dataUser.ppt_high_res,
          this.props.userPreferences && this.props.userPreferences.current_pc,
          this.props.dataUser.ppt_logo
        ).then(() => {
          this.setState({
            preparingSlides: false,
          });
        })
    );
  }
  handleDropdownDelete() {
    this.props.showModal({
      component: Modals.DELETE,
      data: { photos: asArray(this.props.data) }
    });
    this.props.hideMoreMenu();
  }
  handleDropdownRemoveFromCollection() {
    this.props.showModal({
      component: Modals.REMOVE_FROM_COLLECTION,
      data: { photo: this.props.data }
    });
    this.props.hideMoreMenu();
  }
  handleDropdownReport() {
    this.props.showModal({
      component: Modals.REPORT,
      data: { photo: this.props.data }
    });
    this.props.hideMoreMenu();
  }
  handleDropdownAddToCollection() {
    this.props.showModal({
      component: Modals.ADD_TO_COLLECTION,
      data: { photos: asArray(this.props.data) }
    })
    this.props.hideMoreMenu();
  }
  handleDropdownSettings() {
    this.props.showModal({ component: Modals.DESIGN_YOUR_CARDS });
    this.props.hideMoreMenu();
  }
  save() {
    this.props.setData({
      type: "PHOTO_SAVE_UPDATE_LOCALLY",
      data: {
        photo_id: this.props.data.id,
        saved: true,
      },
    });
    this.props.setData({
      type: "ADD_FEED_CACHE_BREAKING_ACTION",
      data: "Saved card",
    });
    this.props.apiCall({
      method: "POST",
      component: "CARD",
      route: "photo/save",
      body: {
        user_id: this.props.dataUser.id,
        photo_id: this.props.data.id,
        save: true,
      },
    });
    if (this.props.dataUser) {
      if (!this.props.dataUser.saved_photos) {
        this.props.setData({ type: "USER_DATA_SET_SAVED_TRUE" });
      }
    }
  }
  unsave() {
    this.props.setData({
      type: "ADD_FEED_CACHE_BREAKING_ACTION",
      data: "Unsaved card",
    });
    this.props.setData({
      type: "PHOTO_SAVE_UPDATE_LOCALLY",
      data: {
        photo_id: this.props.data.id,
        saved: false,
      },
    });
    this.props.apiCall({
      method: "POST",
      component: "CARD",
      route: "photo/save",
      body: {
        user_id: this.props.dataUser.id,
        photo_id: this.props.data.id,
        save: false,
      },
    });
    if (this.props.router.location.pathname.includes("lp/collection/saved/")) {
      console.log("This should add the card to the unsavedList");
      const resetSaved =
        this.props.dataFeed.data.length - this.props.unsavedList.length === 1;
      this.props.setData({
        type: "ADD_TO_UNSAVED",
        data: { id: this.props.data.id, resetSaved: resetSaved },
      });
    }
  }

  render() {
    const showDelete =
      this.props.data.uploader === this.props.dataUser?.id ||
      validPlanIdsForDelete.includes(this.props.dataUser?.plan_id);
    const addToTop =
      showDelete && this.props.isUserCollection
        ? 18.5
        : showDelete || this.props.isUserCollection
        ? 17.5
        : 15.5;
    return ReactDOM.createPortal(
      <>
        <Layer onClick={this.props.hideMoreMenu} />
        <Menu
          style={{
            top: `calc(${this.props.position.y}px - ${addToTop}em)`,
            left: this.props.position.x + this.props.position.width / 3,
          }}
        >
          <Item
            onClick={
              this.state.preparingSlides
                ? null
                : this.props.dataUser?.plan_id > 2
                ? this.handleDownloadSlide
                : this.handleEnterpriseGate
            }
          >
            {this.state.preparingSlides ? (
              <Loader padding="0" svgMargin="0" width="auto" />
            ) : (
              <FaFilePowerpoint />
            )}
            {this.state.preparingSlides ? "Downloading..." : "Download slide"}
            {this.props.dataUser?.plan_id === 1 && (
              <UpgradeIcon />
            )}
          </Item>
          {this.props.data.saved ||
          this.props.router.location.pathname.includes(
            "lp/collection/saved/"
          ) ? (
            <Item onClick={this.unsave}>
              <FaCheck />
              Saved
            </Item>
          ) : (
            <Item onClick={this.save}>
              <FaPlus />
              Save
            </Item>
          )}
          <Item onClick={this.handleDropdownAddToCollection}>
            <FaFolderPlus />
            Add to collection
          </Item>
          {this.props.isUserCollection && (
            <Item onClick={this.handleDropdownRemoveFromCollection}>
              <FaFolderMinus />
              Remove from collection
            </Item>
          )}
          <Item onClick={this.handleDropdownSettings}>
            <FaCog />
            Card settings
          </Item>
          <Link to={`/lp/related/${this.props.data.img}/feed`}>
            <Item>
              <FaSitemap />
              Related photos
            </Item>
          </Link>
          <Item onClick={this.handleDropdownReport}>
            <FaFlag />
            Report
          </Item>
          {showDelete && (
            <Item onClick={this.handleDropdownDelete} red>
              <FaTrash />
              Delete
            </Item>
          )}
        </Menu>
      </>,
      document.getElementById("reactEntry")
    );
  }
}

const mapStateToProps = (state) => ({
  dataFeed: state.dataFeed,
  dataUser: state.dataUser,
  dataLandingPageAnalytics: state.dataLandingPageAnalytics,
  isUserCollection: !!selectUserCollectionById(state, state.dataLandingPageAbout?.id),
  router: state.router,
  unsavedList: state.unsavedList,
  userPreferences: state.userPreferences,
});

const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  showModal: (payload) => dispatch(showModal(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(MoreMenu);
