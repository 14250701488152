import { PureComponent } from "react";

import AltCTA from "components/buttons/alternate/index";
import Loader from "components/loader/index";
import MainCTA from "components/buttons/main/index";
import { connect } from "react-redux";
import styled from "styled-components";
import { hideModal } from "@redux/slices/modalSlice";

const Form = styled.form`
  margin: auto;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.grey5};
  background: ${(props) => props.theme.grey6};
  box-sizing: border-box;
`;
const InnerContainer = styled.div`
  padding: 1em;
`;
const Container = styled.div`
  border: 1px solid ${(props) => props.theme.grey5};
  display: flex;
  transition: ${(props) => props.theme.defaultTransition};
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  svg {
    fill: ${(props) => props.theme.grey2};
  }
`;
const Input = styled.input`
  padding: 1em;
  font-size: 0.75em;
  margin: auto;
  outline: none;
  text-transform: none;
  border: none;
  width: -webkit-fill-available;
  width: 100%;
`;
const CharacterCount = styled.div`
  background: ${(props) => props.theme.grey5};
  padding: 1em;
  margin: 0;
  font-size: 0.8em;
  width: 5em;
`;
const P = styled.p`
  font-size: 0.75em;
  font-weight: bold;
  color: ${(props) => props.theme.grey2};
  text-align: left;
  margin: 0;
  padding: 0.5em 1em 0.5em 1em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 1em 1em 1em;
`;

class Collection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      summary: "",
      submitting: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchReducer !== this.props.fetchReducer) {
      if (
        prevProps.fetchReducer.fetching == true &&
        prevProps.fetchReducer.component == "COLLECTION_CREATE" &&
        this.props.fetchReducer.fetching == false &&
        this.props.fetchReducer.error == false
      ) {
        this.props.hideModal();
      }
    }
  }

  handleNameChange(e) {
    const val = e.target.value.replace("/", "-");
    this.setState({
      name: val,
    });
  }

  handleSummaryChange(e) {
    this.setState({
      summary: e.target.value,
    });
  }

  handleSubmit() {
    this.setState({ submitting: true });
    this.props.apiCall({
      method: "POST",
      component: "COLLECTION_CREATE",
      body: {
        user_id: this.props.dataUser.id,
        name: this.state.name,
        summary: this.state.summary,
        json_data: this.props.saveAutomationRules
          ? this.props.feedFilterRules.new
          : null,
        query_data: this.props.saveAutomationRules
          ? this.props.feedFilterRules.new.filter.map((rule) =>
              rule.map((condition) => ({
                is: condition.is,
                match: condition.match,
                startDate: condition.startDate,
                endDate: condition.endDate,
                ids: condition.item && condition.item.map((item) => item.id),
              }))
            )
          : null,
      },
      route: "collection/owned",
    });
    if (this.props.subModal) {
      this.props.handleBackButton();
    }
  }

  render() {
    return (
      <>
        {this.state.submitting ? (
          <Loader />
        ) : (
          <Form onSubmit={this.handleSubmit}>
            <InnerContainer>
              <Container>
                <Input
                  type="text"
                  maxLength={30}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  placeholder="What should we call this collection?"
                />
                <CharacterCount
                  style={{
                    color: 30 - this.state.name.length < 10 ? "red" : "grey",
                    fontWeight:
                      30 - this.state.name.length < 10 ? "bold" : "normal",
                  }}
                >
                  {30 - this.state.name.length} / 30
                </CharacterCount>
              </Container>
            </InnerContainer>
            <Buttons>
              {this.props.subModal ? (
                <AltCTA
                  type="button"
                  text="Back"
                  clickAction={this.props.handleBackButton}
                />
              ) : null}
              <MainCTA
                type="button"
                blue
                text="Create this collection"
                clickAction={this.handleSubmit}
                disabled={this.state.name.length < 3}
              />
            </Buttons>
          </Form>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dataUser: state.dataUser,
  fetchReducer: state.fetchReducer,
  feedFilterRules: state.feedFilterRules,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (arg) => dispatch({ type: arg.type, data: arg.data }),
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  hideModal: () => dispatch(hideModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
