var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { logout } from "@redux/actions/logout";
import { isCuratedCollection, isCustomCollection, isRetailPipeline, } from "shared/types/collection";
import { hideModal } from "@redux/slices/modalSlice";
export var emptyCuratedCollectionArray = [];
var initialState = {
    collections: emptyCuratedCollectionArray,
    selectedCountry: 5,
    fetchedFromServer: false,
    pendingPipeline: null
};
var curatedCollectionsSlice = createSlice({
    name: 'curatedCollections',
    initialState: initialState,
    reducers: {
        collectionCurationUpdated: function (state, action) {
            if (isCuratedCollection(action.payload)) {
                state.collections.push(action.payload);
            }
            else {
                state.collections = state.collections.filter(function (collection) { return collection.id !== action.payload.id; });
            }
            state.fetchedFromServer = false;
        },
        collectionCountryUpdated: function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.payload.id; });
            if (collection) {
                collection.country_id = action.payload.countryId;
            }
        },
        curatedCollectionCountrySelected: function (state, action) {
            state.selectedCountry = action.payload;
        },
        curatedCollectionDeleted: function (state, action) {
            state.collections = state.collections.filter(function (collection) { return collection.id !== action.payload; });
        },
        newPipelineRequested: function (state, action) {
            state.pendingPipeline = action.payload;
        },
        resetPendingPipeline: function (state) {
            if (state.pendingPipeline) {
                var _a = state.pendingPipeline, questionId = _a.questionId, initialName = _a.initialName;
                state.pendingPipeline = { questionId: questionId, initialName: initialName };
            }
        },
        clearPendingPipeline: function (state) {
            state.pendingPipeline = null;
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase("CURATED_COLLECTIONS_DATA", function (state, action) {
            state.collections = action.data;
            state.fetchedFromServer = true;
        })
            .addCase("CREATE_PIPELINE_COLLECTION_DATA", function (state, action) {
            state.pendingPipeline = __assign(__assign({}, state.pendingPipeline), action.data);
            state.collections.push(action.data);
        })
            .addCase("CHANGE_LANDING_PAGE_PROFILE_PIC", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
            if (collection) {
                collection.source = action.data.source;
                collection.img = action.data.img;
            }
        })
            .addCase("CHANGE_LANDING_PAGE_COVER", function (state, action) {
            if (!action.data.cover.startsWith("blob:")) {
                var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
                if (collection) {
                    collection.cover_url = action.data.cover;
                }
            }
        })
            .addCase("COLLECTION_UPLOAD_COVER_DATA", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.meta.body.landing_page_slug; });
            if (collection) {
                collection.background = action.payload.background;
                collection.cover_url = null;
            }
        })
            .addCase(hideModal, curatedCollectionsSlice.caseReducers.clearPendingPipeline)
            .addCase(logout, function () { return initialState; });
    }
});
export default curatedCollectionsSlice.reducer;
export var collectionCurationUpdated = (_a = curatedCollectionsSlice.actions, _a.collectionCurationUpdated), collectionCountryUpdated = _a.collectionCountryUpdated, curatedCollectionCountrySelected = _a.curatedCollectionCountrySelected, curatedCollectionDeleted = _a.curatedCollectionDeleted, newPipelineRequested = _a.newPipelineRequested, clearPendingPipeline = _a.clearPendingPipeline, resetPendingPipeline = _a.resetPendingPipeline;
export var selectCuratedCollections = function (state) { return state.curatedCollections.collections; };
var selectSelectedCountry = function (state) { return state.curatedCollections.selectedCountry; };
export var selectCuratedCollectionsByType = createSelector([
    selectCuratedCollections,
    function (state, collectionType) { return collectionType; }
], function (curatedCollections, collectionType) {
    switch (collectionType) {
        case "retail_pipelines":
            return curatedCollections.filter(isRetailPipeline);
        case "custom":
            return curatedCollections.filter(isCustomCollection);
        case "curated":
            return curatedCollections;
    }
});
export var selectCuratedCollectionsByTypeAndCountry = createSelector([
    selectCuratedCollectionsByType,
    function (state, collectionType, countryId) { return countryId; }
], function (collections, countryId) {
    if (!countryId)
        return collections;
    return collections.filter(function (collection) { return collection.country_id === countryId; });
});
export var selectCuratedCollectionsByTypeAndSelectedCountry = function (state, collectionType) {
    return selectCuratedCollectionsByTypeAndCountry(state, collectionType, selectSelectedCountry(state));
};
export var selectPendingPipeline = function (state) { return state.curatedCollections.pendingPipeline; };
export var selectPendingPipelineForQuestion = function (state, questionId) {
    var pendingPipeline = selectPendingPipeline(state);
    if ((pendingPipeline === null || pendingPipeline === void 0 ? void 0 : pendingPipeline.questionId) === questionId)
        return pendingPipeline;
};
