var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { logout } from "@redux/actions/logout";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { isCollaborativeCollection, isOwnedCollection } from "shared/types/collection";
var initialState = {
    collections: [],
    fetchedFromServer: false
};
var UserCollectionsSlice = createSlice({
    name: 'userCollections',
    initialState: initialState,
    reducers: {
        userCollectionsReset: function () { return initialState; },
        photoUnlinkedFromCollection: function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.payload.collection_id; });
            if (collection) {
                collection.photo_count = Math.max(0, collection.photo_count - 1);
            }
        },
        photosLinkedToCollections: function (state, action) {
            var linkedCollectionIds = action.payload.collections.map(function (collection) { return collection.id; });
            state.collections
                .filter(function (collection) { return linkedCollectionIds.includes(collection.id); })
                .forEach(function (collection) { collection.photo_count += action.payload.photos.length; });
        },
        collectionCollaboratorsAdded: function (state, action) {
            var _a;
            var collection = state.collections.find(function (collection) { return collection.id === action.payload.collectionId; });
            if (collection) {
                (_a = collection.collaborators).push.apply(_a, action.payload.collaborators);
            }
        }
    },
    extraReducers: function (builder) {
        builder
            .addCase("USER_COLLECTIONS_DATA", function (state, action) {
            return __assign(__assign({}, action.payload), { fetchedFromServer: true });
        })
            .addCase("COLLECTION_CREATE_DATA", function (state, action) {
            state.collections.push(action.payload);
        })
            .addCase("CHANGE_LANDING_PAGE_PROFILE_PIC", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
            if (collection) {
                collection.source = action.data.source;
                collection.img = action.data.img;
            }
        })
            .addCase("CHANGE_LANDING_PAGE_COVER", function (state, action) {
            if (!action.data.cover.startsWith("blob:")) {
                var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
                if (collection) {
                    collection.cover_url = action.data.cover;
                }
            }
        })
            .addCase("COLLECTION_UPLOAD_COVER_DATA", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.meta.body.landing_page_slug; });
            if (collection) {
                collection.background = action.payload.background;
                collection.cover_url = null;
            }
        })
            .addCase("CHANGE_LANDING_PAGE_NAME", function (state, action) {
            var collection = state.collections.find(function (collection) { return collection.id === action.data.landing_page_slug; });
            if (collection) {
                collection.name = action.data.name;
            }
        })
            .addCase("REMOVE_COLLECTION_COLLABORATOR_DATA", function (state, action) {
            var updatedCollection = state.collections.find(function (collection) {
                return collection.id === action.meta.body.collection_id;
            });
            if (updatedCollection) {
                if (updatedCollection.is_admin) {
                    updatedCollection.collaborators = updatedCollection.collaborators.filter(function (collaborator) {
                        return !action.meta.body.member_ids.includes(collaborator.id);
                    });
                }
                else {
                    state.collections = state.collections.filter(function (collection) {
                        return collection.id !== updatedCollection.id;
                    });
                }
            }
        })
            .addCase(logout, function () { return initialState; });
    },
    selectors: {
        selectUserCollections: function (sliceState) { return sliceState.collections; }
    }
});
export var userCollectionsReset = (_a = UserCollectionsSlice.actions, _a.userCollectionsReset), photoUnlinkedFromCollection = _a.photoUnlinkedFromCollection, photosLinkedToCollections = _a.photosLinkedToCollections, collectionCollaboratorsAdded = _a.collectionCollaboratorsAdded;
export var selectUserCollections = UserCollectionsSlice.selectors.selectUserCollections;
export var selectUserOwnedCollections = createSelector([selectUserCollections], function (userCollections) { return userCollections.filter(isOwnedCollection); });
export var selectCollaborativeCollections = createSelector([selectUserCollections], function (userCollections) { return userCollections.filter(isCollaborativeCollection); });
export var selectUserCollectionById = createSelector([selectUserCollections, function (_state, collectionId) { return collectionId; }], function (userCollections, collectionId) {
    return userCollections.find(function (collection) {
        return collection.id === collectionId;
    });
});
export default UserCollectionsSlice.reducer;
