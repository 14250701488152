import { awsPrefix, emptyArray, useCompaniesS3Key } from "constants/variables";
import { Component, createRef } from "react";
import { FaSearch } from "react-icons/fa";
import AltCTA from "components/buttons/alternate/index";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import { Option } from "constants/styled-components";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import { media } from "constants/responsive";
import styled, { withTheme } from "styled-components";
import { avatarFromName, titleCase } from "constants/functions";
import { getImageSource } from "shared/helpers/image";
import { showModal } from "@redux/slices/modalSlice";
import { Modals } from "screens/modal/Modal.constants";
import { apiCall } from "shared/helpers/apiCall";
import CreatableSelect from "react-select/creatable";
import { selectVisibleCollections } from "@redux/selectors/visibleCollections";
import { selectCuratedCollections } from "@redux/slices/curatedCollectionsSlice";
import { asArray } from "shared/helpers/asArray";

// only changes from previous code snippet
const customStyles = {
  multiValue: (base, state) => {
    return state.data.isFixed
      ? {
          ...base,
          backgroundColor: "gray",
          img: { filter: "grayscale(100%) invert(1)" },
        }
      : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

const Wrapper = styled.div`
  margin: ${(props) => (props.horizontalSpace ? "0.25em 0.5em" : props.margin || "0.25em")};
  fill: ${(props) => props.theme.grey2};
  font-size: ${(props) => (props.horizontalSpace ? "1em" : "0.8em")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "20em")};
  min-width: ${(props) => (props.minWidth ? props.minWidth : "7em")};
  width: ${(props) => (props.wide ? "30%" : "auto")};
  .react-select__control--menu-is-open .react-select__value-container {
    &::after {
      color: ${(props) => props.theme.grey1};
      content: ${(props) =>
        props.hideSelectorType
          ? ""
          : props.selectorType
          ? `"Type to search for ${props.selectorType}..."`
          : '"Select"'};
    }
  }
  .react-select__value-container--has-value {
    flex-basis: ${(props) => (props.wrapValues ? "100%" : "auto")};
    max-height: 8em;
    overflow: auto;
  }
  .react-select__input-container {
    color: ${(props) => props.theme.grey1};
    ::after {
        content: attr(data-value) ${props => props.autocomplete ? `"${props.autocomplete}"` : ""} " ";
    }
  }
  .react-select__option--is-focused {
    background-color: ${(props) => props.theme.pastelYellowDarkMode};
    ${media.mobile`
      background-color: inherit;
    `};
  }
  .react-select__single-value {
    color: ${(props) =>
      props.isNegative ? props.theme.deepRed : props.theme.grey1};
    font-weight: ${(props) => (props.isNegative ? "bold" : "normal")};
  }
  &:hover {
    .react-select__control {
      border-color: ${(props) => props.theme.grey3};
    }
  }
  ${media.mobile`
    width: 100%;    
    max-width: 100%;
  `};
`;
const CreateContainer = styled.div`
  text-transform: capitalize;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  svg {
    display: flex;
    fill: ${(props) => props.theme.grey3} !important;
  }
`;

const SuggestionWrapper = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  overflow: hidden 
`;

const SuggestionOverlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.grey3};
  display: flex;
  align-items: center;
  pointer-events: none;
  margin-left: calc(0.5em + 2px);
  white-space: pre-wrap;
  .current-input {
    opacity: 0;
  }
`;

const getStyles = (props) => {
  const { theme, tagging, async, portal } = props;
  if (!tagging && !portal) return customStyles;

  const commonStyles = {
    clearIndicator: (provided) => ({
      ...provided,
      color: theme.grey3,
      "&:hover": {
        color: theme.grey2,
      },
    }),
    control: (base, state) => ({
      ...base,
      cursor: "pointer",
      backgroundColor:
        props.backgroundColor || (
        tagging || !state.hasValue
          ? theme.pastelYellowDarkMode
          : theme.white
        )
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.grey4,
      "&:hover": {
        color: theme.grey3,
      },
      "&:hover > svg": {
        fill: theme.grey3,
      },
    }),
    input: (provided) => ({
      ...provided,
      color: theme.grey1,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.white,
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: theme.white,
    }),
    menuPortal: (provided) => ({
      ...provided,
      pointerEvents: "auto",
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      color: state.isFocused
        ? theme.grey1
        : theme.grey2,
      backgroundColor: state.isDisabled
        ? theme.veryLightGrey
        : state.isFocused
          ? theme.grey6
          : "none",
      ...(state.isDisabled && {
        cursor: 'not-allowed',
        opacity: 0.6,
        ':active': {
          backgroundColor: theme.veryLightGrey
        }
      }),
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.grey1,
    }),
  };

  const asyncStyles = {
    ...(!tagging && {
      valueContainer: (provided) => ({
        ...provided,
        display: "flex",
        flex: "unset",
      }),
      dropdownIndicator: () => ({
        display: "none",
      }),
    }),
    placeholder: (provided) => ({
      ...provided,
      marginLeft: "0.5em",
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      color: theme.grey3,
    }),
  };

  return {
    ...commonStyles,
    ...(async &&
      asyncStyles
    )
  }
};

const negativeOptions = ["is_none", "is_not"];
const autocompleteSelectors = [
  "brands",
  "retailers",
  "countries",
  "categories",
  "channels",
  "regions",
  "demographics",
  "tags",
  "collections",
  "curated_collections"
];

const animatedComponents = makeAnimated();

const ValueContainer = ({ children, ...props }) => (
  components.ValueContainer && (
    <components.ValueContainer {...props}>
      {!props.hasValue && (
        <IconContainer>
          <FaSearch/>
        </IconContainer>
      )}
      {children}
    </components.ValueContainer>
  )
);

const AutocompleteValueContainer = ({ children, ...props }) => {
  const [ValueComponent, InputComponent] = children;
  const { selectRef, isAsync } = props.selectProps;
  const suggestion = selectRef.current?.suggestion;
  const inputValue = selectRef.current?.inputRef?.value;
  const autocompleteValue = suggestion?.slice(inputValue?.length);
  const shouldSuggest = suggestion?.toLowerCase().startsWith(inputValue?.toLowerCase());
  const ValueContainer = isAsync ? components.ValueContainer : animatedComponents.ValueContainer;

  return (
    <ValueContainer {...props}>
      {isAsync && !props.hasValue && (
        <IconContainer>
          <FaSearch/>
        </IconContainer>
      )}
      {ValueComponent}
      <SuggestionWrapper>
        <SuggestionOverlay>
            <span className="current-input">
              {inputValue}
            </span>
          {shouldSuggest && autocompleteValue}
        </SuggestionOverlay>
        {InputComponent}
      </SuggestionWrapper>
    </ValueContainer>
  );
}

const shouldAddOptionsFromState = (props) => !props.async && (!props.options?.length || props.component === "FILTER_RULES");

const getOptionsToUse = (props) => {
  if (!shouldAddOptionsFromState(props)) return props.options;
  const { selectorType, dataInitialLoad, visibleCollections } = props;
  const providedOptions = Array.isArray(props.options) ? props.options : emptyArray;
  const isCollectionSelector = ["collections", "curated_collections"].includes(selectorType);
  const stateOptionSource = isCollectionSelector ? visibleCollections : dataInitialLoad?.[selectorType];

  if (!stateOptionSource) return providedOptions;

  const stateOptions = stateOptionSource.map((item => ({
    ...item,
    type: isCollectionSelector ? "collections" : selectorType,
    id: item.id,
    route: item.route,
    source: item.source,
    value: item.label || item.name,
    primary_color: item.primary_color,
    background: item.background || item.lp_background_img,
    url_slug: item.id,
    img: item.img,
    label: (
      <Option>
        <img src={getImageSource(item)} alt={item.label || item.name}/>
        {item.label || item.name}
      </Option>
    )
  })));

  if (!providedOptions.length) return stateOptions;

  const isGrouped = !!providedOptions[0].options;
  const existingOptionIds = isGrouped
    ? providedOptions.flatMap(group => group.options).map(option => option.id)
    : providedOptions.map(option => option.id);

  const filteredStateOptions = stateOptions.filter(option => !existingOptionIds.includes(option.id));
  return [
    ...providedOptions,
    ...(isGrouped
        ? [{
            label: `All ${isCollectionSelector ? "collections" : selectorType}`,
            options: filteredStateOptions
          }]
        : filteredStateOptions
    )
  ];
};

class Selector extends Component {
  constructor(props) {
    super(props);
    this.mobileMediaQuery = window.matchMedia('(max-width: 420px)');
    this.state = {
      value: props.defaultValue || [],
      inputValue: "",
      menuIsOpen: false,
      isMobile: this.mobileMediaQuery.matches,
      wrapperId: `wrapper-${new Date().getTime()}`,
      inputId: `input-id-${new Date().getTime()}`,
      isSingle: !!(props.isSingle || props.tagging),
      optionsToUse: getOptionsToUse(props),
      autocompleteOptions: null,
      autocompleteId: null
    };
    this.selectRef = createRef();
    this.debounceTimer = createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.noOptionsMessage = this.noOptionsMessage.bind(this);
    this.isOptionDisabled = this.isOptionDisabled.bind(this);
    this.formatCreateLabel = this.formatCreateLabel.bind(this);
    this.updateScreenType = this.updateScreenType.bind(this);
  }

  componentDidMount() {
    this.mobileMediaQuery.addEventListener("change", this.updateScreenType)
    if (!this.props.async) {
      const inputElement = document.getElementById(this.state.inputId);
      if (inputElement) {
        inputElement.autocomplete = "new-password";
      }
    }
    if (this.props.value) {
      this.setState({ value: this.props.value });
    } else if (Array.isArray(this.props.defaultValue)) {
      const value = this.props.defaultValue.map((i) => ({
        ...i,
        value: i.value,
        id: i.id,
        img: i.img,
        primary_color: i.primary_color,
        label: i.noImg ? (
          i.label ? (
            i.label
          ) : (
            i.value
          )
        ) : this.props.selectorType === "months" ? (
          i.value
        ) : (
          <Option>
            {i.img || i.value ? (
              <img
                src={
                  i.img
                    ? useCompaniesS3Key.includes(i.source || i.type)
                      ? `${awsPrefix}companies/${i.img}`
                      : this.props.selectorType === "users"
                      ? i.img
                      : `${awsPrefix}${i.source || i.type}/${i.img}`
                    : avatarFromName(i.name || i.value)
                }
              />
            ) : null}
            {i.name || i.value}
          </Option>
        ),
      }));
      this.setState({ value });
    }
    if (!this.props.dataInitialLoad && shouldAddOptionsFromState(this.props)) {
      this.props.apiCall({
        method: "GET",
        component: "INITIAL_LOAD",
        route: "search/initial_load/",
      });
    }
    if (
      autocompleteSelectors.includes(this.props.selectorType) &&
      !Object.keys(this.props.autocompleteSuggestions).length
    ) {
      this.props.apiCall({
        method: "GET",
        route: `suggestions/autocomplete`,
        component: "AUTOCOMPLETE_SUGGESTIONS"
      });
    }
    if (this.props.shouldFetchCuratedCollections) {
      this.props.apiCall({
        method: "GET",
        component: "CURATED_COLLECTIONS",
        route: `collection/curated_collections`
      });
    }
    if (this.props.shouldFetchUserCollections) {
      this.props.apiCall({
        method: "GET",
        component: "USER_COLLECTIONS",
        route: "collection/owned",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
    if (
      !this.props.async && (
        this.props.options !== prevProps.options ||
        this.props.dataInitialLoad !== prevProps.dataInitialLoad ||
        this.props.visibleCollections !== prevProps.visibleCollections
      )
    ) {
      this.setState({
        optionsToUse: getOptionsToUse(this.props)
      });
    }
    if (
      this.props.defaultValue !== prevProps.defaultValue &&
      Array.isArray(this.props.defaultValue) &&
      (
        (this.props.defaultValue.map((x) => x.id).join("-") || "na") !==
        (prevProps.defaultValue?.map((x) => x.id).join("-") || "na")
      )
    ) { // Re-focus after wrapper remounts due to new default value key
      this.selectRef.current?.focus();
    }
  }

  componentWillUnmount() {
    this.mobileMediaQuery.removeEventListener("change", this.updateScreenType)
  }

  updateScreenType() {
    this.setState({ isMobile: this.mobileMediaQuery.matches });
  }

  handleKeydown(e) {
    if (e.key === "Escape" && this.state.menuIsOpen) {
      e.stopPropagation(); // Prevent modal from closing
      this.selectRef.current?.blur();
      this.selectRef.current?.focus();
    } else if (
      e.key === "Tab" && !e.shiftKey &&
      this.selectRef.current?.suggestion &&
      this.selectRef.current.suggestion !== this.state.inputValue
    ) {
      e.preventDefault();
      this.acceptSuggestion();
    } else if (
      e.key === "ArrowRight" &&
      e.target.selectionStart === e.target.value.length &&
      this.selectRef.current?.suggestion && this.selectRef.current.suggestion !== this.state.inputValue
    ) {
      this.acceptSuggestion();
    }
  }

  handleChange(value, { action, removedValue }) {
    if (action === "create-option") {
      if (typeof this.props.handleCreateOption === "function") {
        this.props.handleCreateOption(value.value);
      } else if (["brands", "companies"].includes(this.props.selectorType)) {
        this.props.showModal({
          component: Modals.CREATE_COMPANY,
          data: {
            companyType: "brand",
            isFlyer: this.props.isFlyer,
            name: titleCase(value.value),
          },
        });
      }
      return;
    }
    if (!this.props.tagging) {
      switch (action) {
        case "remove-value":
        case "pop-value":
          if (removedValue?.isFixed) {
            return;
          }
          break;
        case "clear":
          value = this.state.value?.filter((v) => v.isFixed) || emptyArray;
          break;
      }
      this.setState({
        value: value,
      });
    }
    if (!value && (!this.state.value || !this.state.value.length)) {
      return;
    }
    if (this.props.clickAction) {
      this.props.clickAction({
        type: this.props.component,
        data: value,
        index: this.props.index,
        feedType: this.props.feedType,
      });
    }
  }

  promiseOptions(inputValue, callback) {
    clearTimeout(this.debounceTimer.current);
    this.debounceTimer.current = setTimeout(
      () => this.dataFetchSearch(inputValue).then((x) => callback(x)),
      400
    );
  }

  async dataFetchSearch(inputValue) {
    const { selectorType, pageId, dataLandingPageAbout, component } = this.props;
    const apiRoute =
      selectorType.includes("private")
        ? "private_collection"
        : component === "FILTER_RULES" && ["brands", "retailers", "any_user"].includes(selectorType)
          ? `filters/${selectorType}`
          : "selector";
    try {
      const data = await apiCall({
        route: `search/${apiRoute}/${inputValue}`,
        component: "SELECTOR_SEARCH_OPTIONS",
        method: "GET",
        params: {
          selector: selectorType?.toLowerCase(),
          page: pageId || dataLandingPageAbout?.id,
        }
      })
      return data.map((x) => ({
        type: selectorType,
        id: x.id,
        primary_color: x.primary_color,
        source: x.source,
        url_slug: x.id,
        value: x.name,
        name: x.name,
        img: x.img,
        label:
          selectorType === "languages" ? (
            x.name
          ) : (
            <Option>
              <img
                src={getImageSource(x)}
                alt={x.name}
              />
              {x.name}
            </Option>
          )
      }));
    } catch (err) {
      return emptyArray;
    }
  }

  handleInputChange(newValue, actionMeta) {
    const { async, selectorType } = this.props;
    const inputValue = newValue.replace(/[^0-9a-z -'\-]/gi, "");

    if (async && !inputValue && this.state.autocompleteOptions) {
      this.setState({ autocompleteOptions: null }, () => {
        if (actionMeta.action === "input-change") {
          this.selectRef.current?.openMenu("first");
          this.selectRef.current?.focus();
        }
      });
    }
    if (!this.state.isMobile && autocompleteSelectors.includes(selectorType) && this.selectRef.current) {
      const suggestion = this.getSuggestionFromState(inputValue.toLowerCase());
      if (suggestion || !async || !inputValue) {
        this.updateSuggestion(suggestion);
      } else {
        apiCall({
          route: `search/autocomplete/${selectorType}/${inputValue}`,
          component: "SEARCH_AUTOCOMPLETE_SUGGESTION",
          method: "GET",
          version: 2,
        }).then(
          ({ suggestion }) => this.updateSuggestion(suggestion, true)
        ).catch(
          () => this.updateSuggestion("", true)
        );
      }
    }
    this.setState({ inputValue });
    return inputValue;
  }

  handleMenuOpen() {
    this.setState({ menuIsOpen: true });
  }

  handleMenuClose() {
    this.setState({ menuIsOpen: false });
  }

  itemIsSelected(itemName) {
    return !!this.state.value && asArray(this.state.value).find(selectedValue => selectedValue.value === itemName)
  }

  getSuggestionFromState(inputValue) {
    const { async, autocompleteSuggestions, selectorType, dataInitialLoad, visibleCollections } = this.props;
    const hasFixedOptionList = !async && !shouldAddOptionsFromState(this.props);

    if (inputValue && this.selectRef.current) {
      if (inputValue.length < 4 && !hasFixedOptionList) {
        const suggestion = autocompleteSuggestions[inputValue]?.[selectorType];
        if (suggestion && !this.itemIsSelected(suggestion)) {
          return suggestion;
        }
      }
      if (!async) {
        const suggestionSource =
          hasFixedOptionList
            ? this.props.options
            : selectorType.includes("collections")
              ? visibleCollections
              : dataInitialLoad?.[selectorType];

        const suggestedItem = suggestionSource?.find(item =>
          (item.name || item.value)?.toLowerCase().startsWith(inputValue) &&
          !this.itemIsSelected(item.name || item.value)
        );
        return suggestedItem?.name || suggestedItem?.value;
      }
    }
  }

  updateSuggestion(suggestion, forceRender = false) {
    if (this.selectRef.current) {
      if (!suggestion || this.itemIsSelected(suggestion)) {
        this.selectRef.current.suggestion = "";
      } else {
        this.selectRef.current.suggestion = suggestion;
      }
      if (forceRender) {
        this.setState(prevState => ({ inputValue: prevState.inputValue }));
      }
    }
  }

  acceptSuggestion() {
    const suggestion = this.selectRef.current.suggestion;
    this.setState({ inputValue: suggestion });
    this.selectRef.current.suggestion = "";

    if (this.props.async) {
      clearTimeout(this.debounceTimer.current);
      this.dataFetchSearch(suggestion).then(autocompleteOptions => {
        this.setState({ autocompleteOptions, autocompleteId: new Date().getTime() }, () => {
          this.selectRef.current.openMenu("first");
          this.selectRef.current.focus();
        });
      });
    }
  }

  noOptionsMessage(e) {
    return e.inputValue?.length > 1
      ? `No matching ${this.props.selectorType || "options"} found`
      : `Start typing to search for ${this.props.selectorType || "options"}`
  }

  isOptionDisabled(option) {
    return (
      option.disabled ||
      !!(this.props.filtered && option.id && this.state.value?.[0]?.id === option.id)
    );
  }

  isOptionSelected(option, selectValue) {
    return selectValue.some(selected => selected.value === option.value || selected.id === option.id)
  }

  formatCreateLabel(inputValue) {
    const useDefault = typeof this.props.formatCreateLabel !== "function";
    return (
      <CreateContainer>
        <AltCTA
          dark
          fullWidth
          textTransform={useDefault ? "capitalize" : undefined}
          noMargin
          text={useDefault
            ? `Create ${inputValue}`
            : this.props.formatCreateLabel(inputValue)
          }
        />
      </CreateContainer>
    );
  }

  render() {
    const {
      async,
      tagging,
      creatable,
      portal,
      placeholder,
      isDisabled,
      cacheOptions,
      selectorType,
      defaultValue,
      isSearchable,
      isValidNewOption,
      createOptionPosition,
      disabledPlaceholder,
      hideSelectedOptions,
      hideSelectorType,
      filterOption,
      autoFocus,
    } = this.props;

    const { isSingle, value, optionsToUse, inputId, inputValue, isMobile, autocompleteOptions, autocompleteId } = this.state;
    const defaultValueKey = defaultValue?.length ? defaultValue.map((x) => x.id).join("-") : "na";
    const isAutocomplete = autocompleteSelectors.includes(selectorType);

    const commonProps = {
      styles: getStyles(this.props),
      classNamePrefix: "react-select",
      menuPortalTarget: portal ? document.body : null,
      onInputChange: this.handleInputChange,
      onChange: this.handleChange,
      onMenuOpen: this.handleMenuOpen,
      onMenuClose: this.handleMenuClose,
      onKeyDown: this.handleKeydown,
      ref: this.selectRef,
      options: optionsToUse,
      autoFocus,
      cacheOptions,
      defaultValue,
      isDisabled,
      isSearchable,
      hideSelectedOptions,
      filterOption,
      isMulti: !isSingle,
      isClearable: !isSingle && value?.length > 1 && value.some(v => !v.isFixed),
      noOptionsMessage: this.noOptionsMessage,
      placeholder: !isDisabled ? placeholder : disabledPlaceholder || "Locked",
      ...((!async || tagging) && {
        value
      }),
      ...(isAutocomplete && {
        key: `${autocompleteOptions?.map(x=>x.id)?.join("")}-${autocompleteId}`,
        selectRef: this.selectRef,
        isAsync: async,
        inputValue,
      })
    };

    const asyncProps = {
      components: {
        ValueContainer: isAutocomplete && !isMobile
          ? AutocompleteValueContainer
          : ValueContainer
      },
      loadOptions: this.promiseOptions,
      defaultOptions: autocompleteOptions || optionsToUse
    };

    const syncProps = {
      inputId,
      components: {
        ...animatedComponents,
        ...(isAutocomplete && !isMobile && {
          ValueContainer: AutocompleteValueContainer
        })
      },
      isOptionDisabled: this.isOptionDisabled,
      isOptionSelected: this.isOptionSelected
    };

    const creatableProps = {
      formatCreateLabel: this.formatCreateLabel,
      createOptionPosition: createOptionPosition || "last",
      isValidNewOption: isValidNewOption || ((inputValue) => !!inputValue?.trim())
    };

    return (
      <Wrapper
        maxWidth={this.props.maxWidth}
        minWidth={this.props.minWidth}
        key={`${this.state.wrapperId}-${defaultValueKey}`}
        horizontalSpace={this.props.horizontalSpace}
        margin={this.props.margin}
        isNegative={
          isSingle && value?.length
            ? negativeOptions.includes(value[0]?.id)
            : negativeOptions.includes(value.id)
        }
        style={{ cursor: isDisabled ? "not-allowed" : "inherit" }}
        isDisabled={isDisabled}
        wrapValues={this.props.wrapValues}
        wide={this.props.wide}
        selectorType={selectorType}
        hideSelectorType={(isSingle && !tagging) || inputValue !== "" || value?.length || hideSelectorType}
        autocomplete={this.selectRef.current?.suggestion?.slice(inputValue.length)}
      >
        {async ? (
          creatable ? (
            <AsyncCreatableSelect
              {...commonProps}
              {...creatableProps}
              {...asyncProps}
            />
          ) : (
            <AsyncSelect
              {...commonProps}
              {...asyncProps}
            />
          )
        ) : creatable ? (
          <CreatableSelect
            {...commonProps}
            {...creatableProps}
            {...syncProps}
          />
        ) : (
          <Select
            {...commonProps}
            {...syncProps}
          />
        )}
      </Wrapper>
    );
  }
}

Selector.propTypes = {
  isSingle: PropTypes.bool,
};

Selector.defaultProps = {
  isSingle: false,
};

const mapStateToProps = (state, ownProps) => {
  const stateProps = {
    dataInitialLoad: state.dataInitialLoad,
    dataLandingPageAbout: state.dataLandingPageAbout,
    autocompleteSuggestions: state.autocompleteSuggestions,
  };
  if (["collections", "curated_collections"].includes(ownProps.selectorType)) {
    stateProps.shouldFetchCuratedCollections = !state.curatedCollections.fetchedFromServer;
    if (ownProps.selectorType === "collections") {
      stateProps.shouldFetchUserCollections = !state.userCollections.fetchedFromServer;
    }
    if (shouldAddOptionsFromState(ownProps)) {
      stateProps.visibleCollections = ownProps.selectorType === "collections"
        ? selectVisibleCollections(state)
        : selectCuratedCollections(state);
    } else {
      stateProps.visibleCollections = ownProps.options;
    }
  }
  return stateProps;
};


const mapDispatchToProps = (dispatch) => ({
  apiCall: (payload) => dispatch({ type: "API_CALL_REQUEST", payload }),
  showModal: (payload) => dispatch(showModal(payload))
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(Selector)
);
